/* Grid */
/* Reset style */
.mobile_menu .mod_navigation ul, .mobile_menu .mod_navigation li, .header__navigation ul, .header__navigation li, .reset {
  margin: 0;
  padding: 0;
  list-style: none;
  background: transparent;
  border: 0;
}

/* desktop.css */
*, *:before, *:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

html {
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
}

body {
  margin-bottom: 295px;
  font: 14px/24px "Soho Gothic W01", sans-serif;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: #171715;
}

h1, h2, h3, h4, h5, h6, hgroup, ul, ol, dd, p, figure, pre, table, fieldset, hr {
  margin-top: 0;
  margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 1;
  font-weight: 500;
  font-family: "Soho Gothic W01", sans-serif;
}

h1, h2 {
  position: relative;
  margin-bottom: 25px;
  padding-top: 30px;
  color: #181716;
  font-weight: normal;
  line-height: 1;
}
h1::before, h2::before {
  content: "";
  display: block;
  width: 72px;
  height: 2px;
  background: #e72835;
  position: absolute;
  top: 0;
  left: 0;
}

h1.ce_headline::before,
h2.ce_headline::before {
  left: 10px;
}

h1 {
  font-size: 48px;
  line-height: 52px;
}
@media (max-width: 991px) {
  h1 {
    font-size: 40px;
    line-height: 44px;
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 40px;
}
@media (max-width: 991px) {
  h2 {
    font-size: 30px;
    line-height: 34px;
  }
}

h3 {
  font-size: 18px;
  line-height: 24px;
}

h4, h5, h6 {
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 15px;
  line-height: 30px;
  font-weight: normal;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table td, table th {
  padding: 4px;
  vertical-align: top;
}

blockquote {
  margin-top: 0;
  margin-right: 55px;
  margin-left: 55px;
  text-align: center;
}

blockquote p {
  margin-bottom: 14px;
  font-style: italic;
}

blockquote cite {
  font-style: normal;
}

dl {
  margin: 0 20px 20px;
  padding: 0;
}

dl dt, dl dd {
  margin: 0;
  padding: 8px 34px;
}

dl ul {
  margin: 0;
  padding: 0;
}

dl dt {
  font-weight: 500;
}

dl dd {
  margin-top: -36px;
  padding-left: 205px;
}

dl dt.odd, dl dd.odd {
  background-color: #f7f8f9;
}

dl dt.even, dl dd.even {
  background-color: #f0f2f4;
}

ul {
  padding-left: 16px;
  list-style-type: none;
}

p + ul {
  margin-top: -14px;
}

ul li {
  position: relative;
  margin: 0 0 4px;
  padding: 0 0 0 13px;
}

ul li:before {
  width: 4px;
  height: 4px;
  left: 0;
  top: 8px;
  position: absolute;
  display: block;
  background-color: #171715;
  border-radius: 50%;
  content: "";
}

ul ul {
  margin-top: 5px;
  margin-bottom: 0;
  padding-left: 38px;
}

ul ul li:before {
  width: 3px;
  height: 3px;
  top: 9px;
}

img {
  vertical-align: middle;
}

strong {
  font-weight: 500;
}

a {
  text-decoration: underline;
  color: #df000e;
}

a:hover {
  color: #171715;
}

input {
  filter: none;
}

.block {
  overflow: visible;
}

.mobile-only {
  display: none;
}

#container {
  padding-bottom: 90px;
  min-height: 500px;
}

.mod_article .ce_text:first-child h1 {
  margin-bottom: 20px;
}

.mod_article .ce_text:first-child h1 + p {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 26px;
}

.mod_article .ce_text {
  margin-bottom: 60px;
}

.mod_article .ce_text.short-text {
  max-width: 675px;
}

.ce_cfg_tabs_wrapper_start {
  padding-top: 60px;
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li {
  margin-right: 10px;
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li:before {
  display: none;
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li a {
  background: -moz-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: -webkit-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: -o-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: -ms-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: linear-gradient(to bottom, #e9e9e9, #d5d6d6);
  -pie-background: linear-gradient(top, #e9e9e9, #d5d6d6);
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 0px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  color: #171715;
  border-width: 1px 1px 0 1px !important;
  padding: 10px 22px !important;
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li.active a, .ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li:hover a {
  background: #ffffff;
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_content {
  padding-top: 24px;
  padding-right: 5px;
  padding-left: 5px;
  border-top: 1px solid #e7e7e7;
  border-right: 0px solid #e7e7e7;
  border-bottom: 0px solid #e7e7e7;
  border-left: 0px solid #e7e7e7;
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_content_inner {
  height: auto !important;
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_content [class*=ce_] {
  padding-right: 0;
  padding-left: 0;
}

.ce_download, .ce_downloads {
  margin-bottom: 20px;
  padding-top: 20px;
}

.ce_download img, .ce_downloads img {
  display: none;
}

.ce_download a, .ce_downloads a {
  display: inline-block;
  padding-left: 25px;
  background-image: url("../images/download-pdf.png");
  background-position: left top;
  background-repeat: no-repeat;
}

.ce_download .size, .ce_downloads .size {
  display: none;
}

.ce_gallery ul,
.ce_downloads ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ce_downloads ul li {
  margin: 0 0 5px;
  padding: 0;
}

.ce_gallery ul li:before,
.ce_downloads ul li:before {
  display: none;
}

.ce_gallery ul li {
  margin: 0 10px 10px 0;
  padding: 0;
}

.ce_gallery figure {
  margin: 0;
}

.ce_gallery.clients-gallery ul {
  padding-top: 12px;
}

.ce_gallery.clients-gallery ul li {
  width: 25%;
  height: 80px;
  text-align: center;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.ce_gallery.clients-gallery ul li:before {
  display: none;
}

.ce_gallery.clients-gallery ul li .image_container {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.ce_tinline_youtube .content.active a {
  display: block;
}

.ce_tinline_youtube .content.active .content-inner {
  position: relative;
}

.ce_tinline_youtube .content.active .iframe-box {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
}

.ce_tinline_youtube .iframe-box {
  display: none;
}

.ce_tinline_youtube iframe {
  border: none;
}

.ce_sliderStart .slider-control .slider-prev, .ce_sliderStart .slider-control .slider-next {
  display: none;
}

.ce_sliderStart .slider-control {
  height: auto;
  text-align: center;
}

.ce_sliderStart .slider-control .slider-menu {
  width: auto;
  position: static;
  display: inline-block;
  margin-left: 0;
}

.ce_sliderStart .slider-control .slider-menu b {
  width: 10px;
  height: 10px;
  float: left;
  margin-right: 12px;
  margin-left: 12px;
  background-color: #c4cad6;
  border-radius: 50%;
  text-indent: -9999px;
}

.ce_sliderStart .slider-control .slider-menu b.active {
  background-color: #df000e;
}

.ce_sliderStart.testimonial-slider {
  margin-bottom: 37px;
  padding-top: 22px;
}

.ce_sliderStart.testimonial-slider .slider-control {
  padding-top: 8px;
}

.ce_sliderStart.testimonial-slider .ce_text {
  text-align: center;
}

.ce_sliderStart.testimonial-slider .ce_text p {
  margin-bottom: 0;
}

.arrow-link {
  padding-left: 29px;
  background-image: url("../images/arrow-link.png");
  background-repeat: no-repeat;
  background-position: 13px center;
}

.arrow-link:hover {
  background-image: url("../images/arrow-link-hover.png");
}

.ce_tinline_person,
.article-team .ce_text {
  margin-bottom: 40px;
  text-align: center;
}

.ce_tinline_person .person-image,
.article-team .ce_text .image_container {
  margin-bottom: 16px;
}

.ce_tinline_person .person-image-hover {
  display: none;
}

.ce_tinline_person .person-image:hover .person-image-normal {
  display: none;
}
.ce_tinline_person .person-image:hover .person-image-hover {
  display: block;
}

.featured-link {
  margin-bottom: 48px;
  text-align: center;
}

.featured-link a {
  display: inline-block;
  padding: 5px 33px;
  background-color: #df000e;
  text-decoration: none;
  color: #fff;
}

.featured-link a:before {
  margin-right: 3px;
  content: ">";
}

.featured-link a:hover {
  background-color: #171715;
}

.support-box {
  padding-top: 11px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
}

.support-box .inner {
  display: inline-block;
}

.support-box .inner:after {
  content: "";
  display: table;
  clear: both;
}

.support-box .inner > .phone, .support-box .inner > .email {
  float: left;
  padding-left: 31px;
  text-align: left;
  background-position: left center;
  background-repeat: no-repeat;
  font-size: 16px;
  line-height: 24px;
}

.support-box .inner > .phone {
  margin-right: 90px;
  padding-left: 30px;
  background-image: url("../images/header-phone.png");
}

.support-box .inner > .email {
  padding-left: 35px;
  background-image: url("../images/header-email.png");
}

.support-box .inner > .phone a, .support-box .inner > .email a {
  text-decoration: none;
  color: #171715;
}

.tl_message .tl_confirm {
  font-weight: 500;
  color: #00AE00;
}

#left .inside {
  padding-top: 84px;
  padding-right: 85px;
  padding-left: 10px;
}

#left .inside > * {
  margin-bottom: 72px;
}

#left .inside > *:last-child {
  margin-bottom: 0;
}

#left .left-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#left .left-menu ul li {
  margin: 0 0 17px;
  padding: 0;
}

#left .left-menu ul li:last-child {
  margin-bottom: 0;
}

#left .left-menu ul li:before {
  display: none;
}

#left .left-menu a, #left .left-menu strong {
  display: block;
  padding-bottom: 2px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
  text-decoration: none;
  color: #171715;
  text-transform: uppercase;
}

#left .left-menu a:hover, #left .left-menu a.trail, #left .left-menu strong {
  border-color: #171715;
}

#left .left-menu a.trail, #left .left-menu strong {
  font-weight: 500;
}

#left .ce_hyperlink {
  margin-bottom: 20px;
  padding-right: 0;
  padding-left: 0;
}

#left .ce_hyperlink a {
  display: block;
  padding: 5px 10px;
  text-align: center;
  background-color: #87888b;
  text-decoration: none;
  color: #fff;
}

#left .ce_hyperlink a:last-child {
  margin-bottom: 0;
}

#left .ce_hyperlink.featured a {
  background-color: #df000e;
}

#left .ce_hyperlink a:before {
  margin-right: 3px;
  content: ">";
}

#left .ce_hyperlink a:hover {
  background-color: #171715;
}

#footer {
  width: 100%;
  height: 295px;
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  background-color: #171715;
  font-size: 14px;
  line-height: 24px;
  color: #f3f3f3;
}

#footer a {
  text-decoration: none;
  color: #f3f3f3;
}

#footer a:hover {
  text-decoration: underline;
}

#footer .inner {
  padding-right: 15px;
  padding-left: 15px;
}

#footer .top-box {
  height: 253px;
  overflow: hidden;
  padding-top: 44px;
}

#footer .top-box .inner:after {
  content: "";
  display: table;
  clear: both;
}

#footer .footer-menu {
  width: 880px;
  position: relative;
  float: left;
}

#footer .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#footer .footer-menu ul li {
  margin: 0;
  padding: 0;
}

#footer .footer-menu ul li:before {
  display: none;
}

#footer .footer-menu a.trail, #footer .footer-menu strong.active {
  text-decoration: underline;
}

#footer .footer-menu .level_1 > li {
  margin-bottom: 10px;
  width: 25%;
}

#footer .footer-menu .level_1 > li:first-child {
  float: left;
  width: 50%;
}

#footer .footer-menu .level_1 > li:first-child > .level_2 {
  display: flex;
  flex-wrap: wrap;
}

#footer .footer-menu .level_1 > li:first-child > .level_2 > li {
  width: 35%;
}

#footer .footer-menu .level_1 > li:nth-child(2),
#footer .footer-menu .level_1 > li:nth-child(3),
#footer .footer-menu .level_1 > li:nth-child(4) {
  margin-left: 50%;
  margin-bottom: 20px;
}

#footer .footer-menu .level_1 > li:last-child {
  position: absolute;
  top: 0;
  left: 75%;
}

#footer .footer-menu .level_1 > li > a,
#footer .footer-menu .level_1 > li > strong {
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase;
}

#footer .footer-menu .level_2 > li > strong {
  font-weight: inherit;
}

#footer .top-box .partners {
  display: flex;
  width: 261px;
  float: right;
  margin-top: 6px;
  margin-right: 18px;
}
#footer .top-box .partners:after {
  content: "";
  display: table;
  clear: both;
}
#footer .top-box .partners .partner-col {
  float: left;
}
#footer .top-box .partners .partner-col:first-child {
  margin-right: 15px;
}
#footer .top-box .partners .partner-col:last-child {
  text-align: center;
}
#footer .top-box .partners .partner-col > * {
  display: block;
  margin-bottom: 20px;
}
#footer .top-box .partners .partner-col > *:last-child {
  margin-bottom: 0;
}
#footer .top-box .partners .partner-col > span {
  margin-bottom: 5px;
  color: #8b8a8a;
}

#footer .bottom-box {
  height: 42px;
  overflow: hidden;
  padding-top: 11px;
  border-top: 1px solid #454544;
  font-size: 12px;
  line-height: 20px;
  color: #8b8a8a;
}

#footer .bottom-box a {
  text-decoration: underline;
  color: #8b8a8a;
}

#footer .bottom-box .inner {
  text-align: center;
}

#footer .bottom-box .content {
  display: inline-block;
}

#footer .bottom-box .content:after {
  content: "";
  display: table;
  clear: both;
}

#footer .bottom-box .copyright, #footer .bottom-box .links {
  float: left;
}

#footer .bottom-box .copyright {
  margin-right: 48px;
}

.pagination {
  padding-top: 6px;
  text-align: center;
}

.pagination p {
  display: none;
}

.pagination ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.pagination ul li {
  float: left;
  margin: 0 5px;
  padding: 0;
}

.pagination ul li:before {
  display: none;
}

.pagination ul li.first, .pagination ul li.last {
  display: none;
}

.pagination a, .pagination span {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #171715;
}

.pagination a:hover {
  color: #df000e;
}

.pagination span {
  color: #bcbcbc;
}

.cookiebar {
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: rgba(198, 2, 0, 0.9);
  font-size: 13px;
  font-weight: normal;
}

.cookiebar__text {
  margin-right: 60px;
}

.cookiebar__message {
  margin-right: 0;
}

.cookiebar__link {
  white-space: nowrap;
  transition: all 0.2s;
}

.cookiebar__link:hover {
  text-decoration: underline;
}

.cookiebar__button {
  height: 27px;
  padding: 0 30px;
  background-color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: normal;
  color: #bc0a0a;
  transition: all 0.2s;
}

.cookiebar__button:hover {
  background-color: #171715;
  color: #fff;
}

.article-home-news {
  margin-bottom: 36px;
}

.article-home-news:after {
  content: "";
  display: table;
  clear: both;
}

.article-home-news .ce_headline > * {
  position: relative;
  margin-bottom: 14px;
  margin-left: 10px;
  padding-top: 0;
  text-align: center;
  font-size: 18px;
}

.article-home-news .ce_headline > *:before {
  width: 100%;
  height: 1px;
  top: 50%;
  position: absolute;
  display: block;
  background-color: #e7e7e7;
  content: "";
}

.article-home-news .ce_headline span {
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #fff;
  z-index: 1;
}

.mod_article.article-home-news .home-newslist {
  width: 910px;
  float: left;
  padding-left: 0;
}

.article-home-news .home-newslist:after {
  content: "";
  display: table;
  clear: both;
}

.article-home-news .home-newsticker {
  width: 280px;
  min-height: 223px;
  float: left;
  background-image: url("../images/home-news-ticker.png");
  background-repeat: no-repeat;
  padding: 10px 15px !important;
  background-size: cover;
}

.article-home-news .home-newsticker h3 {
  margin-bottom: 16px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #df000e;
}

.article-home-news .home-newsticker .more-container {
  display: flex;
  justify-content: center;
}

.article-home-news .home-newsticker .more {
  margin-bottom: 0;
  margin-left: -24px;
}

.article-home-news .layout_simple {
  margin-bottom: 10px;
}

.article-home-news .layout_simple a {
  display: inline-block;
  padding-left: 12px;
  background-image: url("../images/home-news-ticker-arrow.png");
  background-repeat: no-repeat;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  text-decoration: none;
  color: #171715;
  background-position: left 5px;
}

.article-home-news .layout_simple a:hover {
  text-decoration: underline;
}

.article-home-news .layout_latest h2 {
  margin-top: 16px;
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}

.article-home-news .layout_latest h2 a {
  text-decoration: none;
  color: #171715;
}

.article-home-news .layout_latest h2 a:hover {
  text-decoration: underline;
}

.article-home-news .layout_latest .image_container {
  margin-bottom: 15px;
}

.article-home-news .layout_latest .more {
  margin-bottom: 0;
}

.article-home-featured {
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.article-home-featured .inner {
  height: 423px;
  padding-top: 63px;
  padding-right: 65px;
  padding-left: 65px;
  background-image: url("../images/home-featured-article-bg.jpg");
  background-repeat: no-repeat;
}

.article-home-featured .ce_text:first-child h1 {
  margin-bottom: 7px;
  font-weight: 500;
}

.article-home-featured .ce_text p {
  margin-left: 3px;
}

.article-home-featured .ce_text p:last-child {
  margin-left: 0;
  padding-top: 15px;
}

.article-home-featured .ce_text p:last-child a {
  display: inline-block;
  padding: 5px 33px;
  background-color: #df000e;
  text-decoration: none;
  color: #fff;
}

.article-home-featured .ce_text p:last-child a:before {
  margin-right: 3px;
  content: ">";
}

.article-home-featured .ce_text p:last-child a:hover {
  background-color: #171715;
}

.article-home-featured .ce_image .image_container, .article-home-featured .ce_tinline_youtube .content {
  padding-top: 52px;
  padding-left: 10px;
}

.page-layout-homepage #header .mod_article .ce_sliderStart {
  margin-bottom: 35px;
}

.page-layout-product #header .mod_article .inner:after {
  content: "";
  display: table;
  clear: both;
}

.page-layout-product #header .mod_article .ce_image {
  float: left;
}

.page-layout-product #header .mod_article .ce_text {
  margin-top: 82px;
  margin-left: 381px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.page-layout-product #header .mod_article .ce_text h1 {
  margin-bottom: 12px;
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  color: #fff;
}

.page-layout-product #header .mod_article .ce_text p {
  margin-bottom: 27px;
  margin-left: 3px;
}

.page-layout-product #header .mod_article .ce_tinline_youtube {
  margin-left: 381px;
}

.page-layout-product #container {
  padding-top: 34px;
}

body.page-layout-product #main {
  padding-right: 150px;
}

body.page-layout-product.page-fullwidth #main {
  padding-right: 0;
}

.ce_tinline_courses {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.ce_tinline_courses .filters {
  margin-top: -60px;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ce_tinline_courses .filters a {
  position: relative;
  display: block;
  margin-bottom: 37px;
  padding-right: 10px;
  padding-bottom: 11px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #171715;
}

.ce_tinline_courses .filters a.active:before, .ce_tinline_courses .filters a:hover:before {
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: #171715;
  content: "";
}

.ce_tinline_courses .status-legend {
  margin-bottom: 11px;
}

.ce_tinline_courses .status-legend:after {
  content: "";
  display: table;
  clear: both;
}

.ce_tinline_courses .status-legend ul {
  float: right;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ce_tinline_courses .status-legend ul li {
  position: relative;
  float: left;
  margin: 0 31px 0 0;
  padding: 0 0 0 16px;
  font-size: 12px;
  line-height: 1;
  color: #8b8b8a;
}

.ce_tinline_courses .status-legend ul li:last-child {
  margin-right: 0;
}

.ce_tinline_courses .status-legend ul li:before {
  width: 9px;
  height: 9px;
  left: 0;
  top: 1px;
  position: absolute;
  display: block;
  border-radius: 50%;
  content: "";
}

.ce_tinline_courses .status-legend ul li.available:before, .ce_tinline_courses .course.status_available .title strong:before {
  background-color: #04e328;
}

.ce_tinline_courses .status-legend ul li.waiting:before, .ce_tinline_courses .course.status_waiting .title strong:before {
  background-color: #ffa800;
}

.ce_tinline_courses .status-legend ul li.closed:before, .ce_tinline_courses .course.status_closed .title strong:before {
  background-color: #f00;
}

.ce_tinline_courses .courses {
  margin-bottom: 30px;
}

.ce_tinline_courses .course {
  margin-bottom: 10px;
  padding-top: 16px;
  padding-bottom: 31px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.ce_tinline_courses .course:last-child {
  margin-bottom: 0;
}

.ce_tinline_courses .course--hidden {
  display: none;
}

.ce_tinline_courses .courses-more {
  margin-top: 30px;
  text-align: center;
}

.ce_tinline_courses .courses-more-link {
  display: inline-block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #181716;
  transition: background-color 0.2s;
}
.ce_tinline_courses .courses-more-link:hover {
  color: #fff;
  background-color: #df000e;
}

.ce_tinline_courses .course .info-box {
  margin-bottom: 15px;
}

.ce_tinline_courses .course .info-box-mobile {
  display: none;
}

.ce_tinline_courses .course .title strong {
  position: relative;
  display: block;
  margin-bottom: 1px;
  padding-left: 34px;
  font-size: 15px;
  font-weight: 500;
}

.ce_tinline_courses .course .title strong:before {
  width: 9px;
  height: 9px;
  left: 12px;
  top: 6px;
  position: absolute;
  display: block;
  border-radius: 50%;
  content: "";
}

.ce_tinline_courses .course .title span {
  padding-left: 34px;
  font-size: 12px;
  line-height: 18px;
  color: #8b8b8a;
}

.ce_tinline_courses .course .description {
  margin-bottom: 10px;
  margin-left: 34px;
}

.ce_tinline_courses .course .link {
  margin-left: 34px;
}

.ce_tinline_courses .pagination {
  margin-bottom: 30px;
}

.ce_tinline_courses .individual-course {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 33px 28px;
  background-color: #f0f2f4;
  border-top: 1px solid #dadcdd;
  border-bottom: 1px solid #dadcdd;
}
@media (max-width: 991px) {
  .ce_tinline_courses .individual-course {
    flex-direction: column;
  }
}

.ce_tinline_courses .individual-course__headline {
  width: 50%;
  padding-left: 43px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .ce_tinline_courses .individual-course__headline {
    margin-bottom: 10px;
    width: 100%;
    padding-left: 0;
    font-size: 25px;
    text-align: center;
  }
}

.ce_tinline_courses .individual-course__content {
  width: 50%;
  padding-left: 15px;
}
@media (max-width: 991px) {
  .ce_tinline_courses .individual-course__content {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.ce_tinline_courses .individual-course__content p {
  margin-bottom: 20px;
}

.ce_tinline_courses .individual-course__link {
  display: inline-block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #df000e;
  transition: background-color 0.2s;
}
.ce_tinline_courses .individual-course__link:hover {
  color: #fff;
  background-color: #181716;
}

.ce_tinline_courses .individual-course p:last-child a:before {
  margin-right: 3px;
  content: ">";
}

.ce_tinline_courses .individual-course p:last-child a:hover {
  background-color: #171715;
}

.mod_tinline_course_reader .header h1 + p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 26px;
}

.mod_tinline_course_reader .container:after {
  content: "";
  display: table;
  clear: both;
}

.mod_tinline_course_reader .details-column > div {
  margin-bottom: 36px;
  padding: 21px 23px 26px;
  background-color: #f0f2f4;
}

.mod_tinline_course_reader .details-column h3 {
  margin-bottom: 12px;
  padding-top: 0;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

.mod_tinline_course_reader .details-column .overview-box .details .row {
  margin-bottom: 15px;
}

.mod_tinline_course_reader .details-column .overview-box .details .row:last-child {
  margin-bottom: 0;
}

.mod_tinline_course_reader .details-column .overview-box .details .row:after {
  content: "";
  display: table;
  clear: both;
}

.mod_tinline_course_reader .details-column .overview-box .details .row-label {
  width: 40%;
  float: left;
  padding-right: 10px;
}

.mod_tinline_course_reader .details-column .overview-box .details .row-value {
  width: 60%;
  float: left;
  padding-left: 10px;
  font-weight: 500;
}

.mod_tinline_course_reader .details-column .overview-box .details .row-value span {
  font-weight: 300;
}

.mod_tinline_course_reader .details-column .subscribe-link {
  padding-top: 25px;
  text-align: center;
}

.mod_tinline_course_reader .details-column .subscribe-link a {
  display: block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #df000e;
  transition: background-color 0.2s;
}
.mod_tinline_course_reader .details-column .subscribe-link a:hover {
  color: #fff;
  background-color: #181716;
}

.mod_tinline_course_reader .details-column .subscribe-link a:hover {
  background-color: #171715;
}

.mod_tinline_course_reader .details-column .address-box .address {
  margin-bottom: 28px;
}

.mod_tinline_course_reader .content-column .description {
  margin-bottom: 60px;
}

.mod_tinline_course_reader .content-column .description p {
  margin-bottom: 40px;
}

.mod_tinline_course_reader .content-column .subscription-form h2 {
  margin-bottom: 29px;
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .widget > label, .mod_tinline_course_reader .content-column .subscription-form .formbody .widget > fieldset > legend {
  width: 146px;
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .widget-textarea {
  margin-bottom: 3px;
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .explanation {
  margin-left: 146px;
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .submit {
  margin-left: 358px;
}

body.page-layout-course #container {
  width: 1200px;
}

.page-layout-content #container {
  width: 900px;
}

.newslist-full .layout_latest {
  margin-bottom: 10px;
  padding: 16px 34px 31px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.newslist-full .layout_latest h2 {
  margin-top: 0;
  margin-bottom: 1px;
  font-size: 15px;
  line-height: 20px;
}
.newslist-full .layout_latest h2::before {
  display: none;
}

.newslist-full .layout_latest h2 a {
  text-decoration: none;
  color: #171715;
}

.newslist-full .layout_latest h2 a:hover {
  text-decoration: underline;
}

.newslist-full .layout_latest .info {
  margin-bottom: 15px;
  font-size: 13px;
  color: #8b8b8a;
}

.newslist-full .layout_latest .info .divider {
  margin-right: 5px;
  margin-left: 5px;
}

.newslist-full .layout_latest .teaser p {
  margin-bottom: 20px;
}

.newslist-full .layout_latest .teaser p:last-child {
  margin-bottom: 0;
}

.newslist-full .layout_latest .more {
  margin-top: 20px;
  margin-bottom: 0;
}

.mod_newsreader .layout_full h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #171715;
}
.mod_newsreader .layout_full h1::before {
  display: none;
}

.mod_newsreader .layout_full .info {
  font-size: 13px;
  color: #8b8b8a;
}

.news-navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.news-navigation ul li {
  margin: 0;
  padding: 0;
}

.news-navigation ul li:not(:last-child) {
  margin-right: 38px;
}

.news-navigation ul li:before {
  display: none;
}

.news-navigation a:not(.invisible), .news-navigation strong {
  position: relative;
  display: block;
  padding-right: 10px;
  padding-bottom: 11px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #171715;
}

.news-navigation a:hover:before, .news-navigation a.trail:before, .news-navigation strong:before {
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: #171715;
  content: "";
}

body.page-layout-contact #header .mod_article {
  padding-top: 0;
  background: none;
}

body.page-layout-contact #header .intro__map {
  position: relative;
}

body.page-layout-contact #header .intro__map:after {
  width: 1180px;
  height: 130px;
  bottom: -78px;
  left: 10px;
  position: absolute;
  display: block;
  background-color: #fff;
  content: "";
  -ms-transform: skewY(-5deg); /* IE 9 */
  -webkit-transform: skewY(-5deg); /* Safari */
  transform: skewY(-5deg); /* Standard syntax */
}

body.page-layout-contact #header .intro__map .gm-style > div:last-child {
  top: 20px !important;
  right: 0 !important;
  left: auto !important;
  bottom: auto !important;
}

body.page-layout-contact #header .intro__map .gm-style > .gmnoprint, body.page-layout-contact #header .intro__map .gm-style > .gmnoscreen, body.page-layout-contact #header .intro__map .gm-style > .gm-style-cc {
  top: 0 !important;
  bottom: auto !important;
}

body.page-layout-contact #header .ce_text {
  position: relative;
  margin-top: -8px;
  font-size: 18px;
  line-height: 26px;
  z-index: 1;
}

body.page-layout-contact #header .ce_text p {
  margin-bottom: 3px;
}

body.page-layout-contact #header .ce_text p:last-child {
  margin-bottom: 0;
}

body.page-layout-search #header {
  margin-bottom: 0;
}

body.page-layout-search .search-menu {
  padding-right: 0;
  padding-left: 0;
}

body.page-layout-search .search-menu ul {
  height: 31px;
  position: relative;
  margin: 0 0 11px;
  padding: 0;
  list-style-type: none;
}

body.page-layout-content .search-menu ul:after {
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: #e7e7e7;
  content: "";
}

body.page-layout-search .search-menu ul li {
  height: 100%;
  float: left;
  margin: 0 42px 0 0;
  padding: 0;
}

body.page-layout-search .search-menu ul li:before {
  display: none;
}

body.page-layout-search .search-menu a, body.page-layout-search .search-menu span {
  height: 100%;
  position: relative;
  display: block;
  text-decoration: none;
  color: #171715;
  z-index: 1;
}

body.page-layout-search .search-menu a:hover, body.page-layout-search .search-menu a.trail, body.page-layout-search .search-menu span {
  border-bottom: 2px solid #171715;
}

body.page-layout-search .search-menu a.invisible {
  position: absolute !important;
}

body.page-layout-search .mod_search > * {
  width: 900px;
  margin-right: auto;
  margin-left: auto;
}

body.page-layout-search .mod_search .formbody {
  width: 900px;
  margin: 30px auto;
}

body.page-layout-search .mod_search .formbody .widget-text {
  display: flex;
}

body.page-layout-search .mod_search .formbody .widget-text label {
  padding-top: 0;
}

body.page-layout-search .mod_search .formbody .text {
  width: 100%;
  float: none;
  padding-right: 8px;
  padding-left: 8px;
  border-right: 0;
}

body.page-layout-search .mod_search .formbody .text:focus + .submit {
  border-color: #171715;
}

body.page-layout-search .mod_search .formbody .widget-submit {
  margin-bottom: 0;
}

body.page-layout-search .mod_search .formbody .submit {
  margin-left: 0;
  min-width: auto;
  width: 50px;
  height: 40px;
  right: 0;
  top: 0;
  background: #fff url("../images/header-search.svg") center center no-repeat;
  background-size: 20px auto;
  border-top: 1px solid #d1d1d0;
  border-right: 1px solid #d1d1d0;
  border-bottom: 1px solid #d1d1d0;
  border-left: 0 solid #d1d1d0;
  text-indent: -9999px;
  border-radius: 0;
}

body.page-layout-search .mod_search .search-header {
  margin-bottom: 15px;
  font-size: 12px;
  color: #bcbcbc;
}

body.page-layout-search .mod_search > div {
  margin-bottom: 29px;
}

body.page-layout-search .mod_search > div.last {
  margin-bottom: 36px;
}

body.page-layout-search .mod_search h3 {
  margin-bottom: 1px;
  font-weight: 500;
}

body.page-layout-search .mod_search h3 a {
  text-decoration: none;
  color: #171715;
}

body.page-layout-search .mod_search h3 a:hover {
  text-decoration: underline;
}

body.page-layout-search .mod_search h3 .relevance, body.page-layout-search .mod_search .filesize {
  display: none;
}

body.page-layout-search .mod_search p {
  margin-bottom: 0;
}

body.page-layout-search .mod_search .context {
  margin-bottom: 5px;
}

body.page-layout-search .mod_search .url {
  color: #df000e;
}

body.page-layout-search .mod_search .pagination {
  margin-bottom: 0;
  padding-top: 16px;
  border-top: 1px solid #e7e7e7;
}

.mod_sitemap:after {
  content: "";
  display: table;
  clear: both;
}

.mod_sitemap > div {
  width: 280px;
  float: left;
  margin-right: 20px;
  line-height: 25px;
}

.mod_sitemap > div.group-3 {
  margin-right: 0;
}

.mod_sitemap ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mod_sitemap ul li {
  margin: 0;
  padding: 0;
}

.mod_sitemap ul li:before {
  display: none;
}

.mod_sitemap a {
  text-decoration: none;
  color: #171715;
}

.mod_sitemap a:hover {
  text-decoration: underline;
}

.mod_sitemap .level_1 > li {
  margin-bottom: 70px;
}

.mod_sitemap .level_1 > li > a, .mod_sitemap .level_1 > li > span {
  font-size: 18px;
  font-weight: 500;
}

.mod_sitemap .level_2 {
  margin-top: 10px;
}

.mod_sitemap .level_3 {
  margin-top: 5px;
  margin-bottom: 25px;
}

.mod_sitemap .level_4 {
  display: none;
}

.mod_sitemap .group-1 .level_2 {
  margin-top: 15px;
}

.mod_sitemap .group-1 .level_2 > li > a, .mod_sitemap .group-1 .level_2 > li > span {
  font-size: 15px;
  font-weight: normal;
}

.mod_tinline_downloads .filter-container {
  margin-bottom: 20px;
}

.mod_tinline_downloads .filter-container form {
  text-align: center;
}

.mod_tinline_downloads .filter-container .formbody {
  display: inline-block;
  text-align: left;
}

.mod_tinline_downloads .filter-container label {
  width: 150px;
}

.mod_tinline_downloads .downloads-group {
  margin-bottom: 10px;
}

.mod_tinline_downloads .downloads-group .group-header {
  padding-left: 10px;
  background-image: url("../images/mobile/tabs-arrow-down.png");
  background-repeat: no-repeat;
  border-bottom: 1px solid #e7e7e7;
  background-position: 98% center;
  background-size: 12px 8px;
}

.mod_tinline_downloads .downloads-group .group-header.active {
  background-image: url("../images/mobile/tabs-arrow-up.png");
  border-color: #df000e;
}

.mod_tinline_downloads .downloads-group .group-link {
  padding-top: 0;
  cursor: pointer;
}

.mod_tinline_downloads .downloads-group .downloads-items {
  padding: 20px;
}

.mod_tinline_downloads .download-item {
  margin-bottom: 10px;
}

.mod_tinline_downloads .download-item:last-child {
  margin-bottom: 0;
}

.mod_tinline_downloads .download-item .details {
  padding: 10px;
}

.mod_tinline_downloads .download-item .details p:last-child {
  margin-bottom: 0;
}

.mod_tinline_downloads .download-header {
  border-bottom: 2px solid #e7e7e7;
}

.mod_tinline_downloads .download-header:after {
  content: "";
  display: table;
  clear: both;
}

.mod_tinline_downloads .download-header .title {
  float: left;
}

.mod_tinline_downloads .download-header .actions {
  float: right;
}

.mod_tinline_downloads .download-header .actions:after {
  content: "";
  display: table;
  clear: both;
}

.mod_tinline_downloads .download-header .actions a {
  float: left;
  display: block;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #e7e7e7;
  text-decoration: none;
}

.mod_tinline_downloads .download-header .actions a:first-child {
  margin-left: 0;
}

@media (max-width: 1199px) {
  /* desktop-small.css */
  .cookiebar--active {
    flex-direction: column;
  }
  .cookiebar__text {
    margin-right: 0;
    margin-bottom: 1em;
  }
  #header .top-bar .panels li.panel-login .content .inner {
    padding-right: 0;
    padding-left: 0;
  }
  #header .main-menu .level_1 > li {
    width: 115px;
  }
  #header .main-menu .level_1 > li:first-child {
    width: 170px;
  }
  #header .middle-box .inner:before {
    width: 955px;
  }
  #header .ce_tinline_slide .content {
    width: 300px;
    padding-bottom: 24px;
    padding-left: 24px;
  }
  #header .ce_tinline_slide .headline {
    font-size: 32px;
    line-height: 36px;
  }
  #header .ce_tinline_slide .subheadline {
    margin-bottom: 12px;
  }
  #left .inside {
    padding-right: 42px;
  }
  #left {
    width: 257px;
    right: 257px;
  }
  .page-layout-product #container {
    padding-left: 257px;
  }
  .article-home-news .ce_headline > * {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mod_article.article-home-news .mod_newslist {
    padding-right: 0;
    padding-left: 40px;
  }
  .mod_article.article-home-news .home-newslist {
    width: 650px;
  }
  .mod_article.article-home-news .home-newslist .layout_latest.last {
    display: none;
  }
  .article-home-featured .inner {
    padding-right: 30px;
    padding-left: 30px;
  }
  .page-layout-product #header .mod_article .inner {
    padding-left: 34px;
  }
  body.page-layout-product #main {
    padding-right: 0;
  }
  body.page-layout-search #header:after {
    width: 960px;
    margin-left: -480px;
    background-size: contain;
  }
  body.page-layout-course #container {
    width: 980px;
  }
  .mod_tinline_course_reader .content-column {
    width: 655px;
  }
  #footer .footer-menu {
    width: 640px;
  }
  #footer .footer-menu .level_1 > li {
    width: 25%;
  }
}
@media (max-width: 991px) {
  /* mobile-medium.css */
  body {
    margin-bottom: 0;
  }
  blockquote {
    margin-right: 0;
    margin-left: 0;
  }
  dl {
    margin-right: 0;
    margin-left: 0;
  }
  dl dt, dl dd {
    margin-top: 0;
    padding-right: 22px;
    padding-left: 22px;
  }
  dl dt {
    padding-top: 6px;
    padding-bottom: 5px;
  }
  dl dd {
    padding-top: 0;
  }
  dl ul {
    margin-left: 33px;
  }
  #header {
    margin-bottom: 6px;
  }
  #header .top-bar .panels li .trigger-container:before {
    width: 2px;
  }
  #header .top-bar .panels li.panel-login .content .inner {
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
  }
  #header .top-bar .panels li.panel-login .content .login-box, #header .top-bar .panels li.panel-login .content .registration-box {
    width: 100%;
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  #header .top-bar .panels li.panel-login .content .submit_container {
    text-align: left;
  }
  #header .middle-box .inner {
    overflow: hidden;
    padding-bottom: 10px;
  }
  #header .middle-box .inner:before {
    width: 100%;
    height: 3px;
  }
  #header .main-menu {
    display: none;
  }
  #header .logo {
    display: none;
  }
  #header .logo-mobile {
    width: 120px;
    float: left;
    display: block;
    margin-top: 30px;
  }
  #header .ce_tinline_slide .content {
    width: 240px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  #header .ce_tinline_slide .headline {
    font-size: 22px;
    line-height: 26px;
  }
  #header .ce_tinline_slide .subheadline {
    font-size: 14px;
    line-height: 32px;
  }
  #header .ce_tinline_slide .link a {
    font-size: 13px;
  }
  #header .mod_article .slider-control {
    bottom: 5px;
  }
  .arrow-link {
    background-image: url("../images/mobile/arrow-link.png");
    background-size: 9px 6px;
  }
  .arrow-link:hover {
    background-image: url("../images/mobile/arrow-link-hover.png");
  }
  .article-home-featured .inner {
    height: auto;
    padding: 30px 10px 12px;
    background: #f8f8f8;
  }
  .article-home-news .ce_headline > * {
    margin-right: 0;
    margin-left: 0;
  }
  .mod_article.article-home-news .mod_newslist {
    padding-right: 0;
    padding-left: 0;
  }
  .mod_article.article-home-featured .ce_text:first-child {
    text-align: left;
  }
  .article-home-featured .ce_text p:last-child {
    margin-bottom: 32px;
    padding-top: 10px;
    text-align: center;
  }
  .mod_sitemap .level_1 > li {
    margin-bottom: 20px;
  }
  .ce_gallery.clients-gallery ul li {
    width: 50%;
  }
  .ce_tinline_courses {
    margin-top: 0;
  }
  .ce_tinline_courses .filters {
    padding-right: 0;
    padding-left: 0;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .ce_tinline_courses .filters a {
    margin-bottom: 20px;
  }
  .ce_tinline_courses .status-legend {
    margin-bottom: 2px;
  }
  .ce_tinline_courses .status-legend ul li {
    float: none;
    margin-right: 0;
    margin-bottom: 8px;
    padding-right: 16px;
    padding-left: 0;
    text-align: right;
  }
  .ce_tinline_courses .status-legend ul li:before {
    left: auto;
    right: 0;
  }
  .ce_tinline_courses .course {
    margin-bottom: 9px;
    padding-bottom: 29px;
    border-top-width: 2px;
    border-bottom-width: 2px;
  }
  .ce_tinline_courses .course .info-box-mobile {
    display: block;
  }
  .ce_tinline_courses .course .info-box {
    display: none;
  }
  .ce_tinline_courses .info-box-mobile > * {
    padding-left: 22px;
  }
  .ce_tinline_courses .info-box-mobile .row {
    margin-bottom: 11px;
  }
  .ce_tinline_courses .info-box-mobile .price {
    margin-bottom: 30px;
  }
  .ce_tinline_courses .info-box-mobile .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .ce_tinline_courses .info-box-mobile .row > *:first-child {
    width: 65%;
    float: left;
  }
  .ce_tinline_courses .info-box-mobile .row > *:last-child {
    width: 35%;
    float: left;
  }
  .ce_tinline_courses .course .info-box-mobile > .title {
    margin-bottom: 15px;
    padding-left: 0;
  }
  .ce_tinline_courses .course .title strong, .ce_tinline_courses .course .title span {
    padding-left: 22px;
  }
  .ce_tinline_courses .course .title strong:before {
    left: 7px;
  }
  .ce_tinline_courses .course .description {
    margin-bottom: 0;
    margin-left: 22px;
  }
  .ce_tinline_courses .course .description p {
    margin-bottom: 10px;
  }
  .ce_tinline_courses .course .link {
    margin-left: 22px;
  }
  .ce_tinline_courses .individual-course {
    border-top-width: 2px;
    border-bottom-width: 2px;
  }
  .ce_tinline_courses .individual-course p:last-child {
    text-align: center;
  }
  #container {
    padding-bottom: 38px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #left .inside {
    padding-right: 10px;
  }
  #left .inside > * {
    margin-bottom: 30px;
  }
  #left .mod_article {
    width: 206px;
    margin-right: auto;
    margin-bottom: 70px;
    margin-left: auto;
    padding-top: 40px;
  }
  #footer {
    height: auto;
    position: static;
  }
  #footer .top-box {
    height: auto;
    padding-top: 45px;
    padding-bottom: 45px;
    text-align: center;
  }
  #footer .footer-menu {
    display: none;
  }
  #footer .top-box .partners {
    float: none;
    justify-content: center;
    width: 100%;
    margin: 0;
  }
  #footer .bottom-box {
    height: auto;
    padding-bottom: 14px;
    border-top-width: 2px;
  }
  #footer .bottom-box .copyright {
    float: none;
    margin-right: 0;
  }
  #footer .bottom-box .links {
    float: none;
  }
  .page-layout-content #container {
    width: auto;
  }
  body.page-layout-contact #header .mod_article {
    width: auto;
  }
  body.page-layout-contact #header .intro__map {
    padding-right: 0;
    padding-left: 0;
  }
  body.page-layout-contact #header .intro__map > * {
    height: 200px !important;
  }
  body.page-layout-contact #header .intro__map:after {
    display: none;
  }
  body.page-layout-contact #header .ce_text {
    margin-top: 20px;
  }
  .contact-form .formbody {
    padding-top: 20px;
  }
  body.page-layout-course #container {
    width: auto;
  }
  body.page-layout-search #header:after {
    width: 100%;
  }
  body.page-layout-search .mod_search .formbody {
    width: auto;
    margin-bottom: 30px;
  }
  body.page-layout-search .mod_search > * {
    width: auto;
  }
  body.page-layout-search .search-menu ul {
    height: auto;
  }
  body.page-layout-search .search-menu ul li {
    height: auto;
    float: none;
    margin-right: 0;
    margin-bottom: 10px;
  }
  body.page-layout-content .search-menu ul:after {
    display: none;
  }
  body.page-layout-search .search-menu a, body.page-layout-search .search-menu span {
    border-bottom: 1px solid #e7e7e7;
  }
  .article-home-news .mod_newslist .layout_latest {
    width: 33.333%;
  }
  .mod_article.article-home-news .home-newslist {
    width: 100%;
    float: none;
    clear: both;
    margin-bottom: 40px;
  }
  .mod_article.article-home-news .home-newslist .layout_latest.last {
    display: block;
  }
  .article-home-news .mod_newslist {
    padding-right: 0;
    padding-left: 0;
  }
  .article-home-news .home-newsticker {
    float: none;
    clear: both;
    background-image: url("../images/home-news-ticker-mobile.jpg");
    background-size: cover;
    min-height: 0;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .newslist-full .layout_latest {
    padding-right: 0;
    padding-left: 0;
  }
  .news-navigation ul {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .news-navigation ul li {
    margin-bottom: 20px;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .support-box:after {
    content: "";
    display: table;
    clear: both;
  }
  .support-box .inner {
    display: block;
  }
  .support-box .inner > .phone, .support-box .inner > .email {
    width: 50%;
    float: left;
    margin-right: 0;
    margin-left: 0;
    padding-top: 28px;
    padding-left: 0;
    text-align: center;
    background-position: center top;
  }
  .support-box .inner > .phone {
    background-image: url("../images/mobile/header-phone.png");
    background-size: 23px 23px;
  }
  .support-box .inner > .email {
    background-image: url("../images/mobile/header-email.png");
    background-size: 23px 16px;
    background-position: center 5px;
  }
  .article-team .ce_text {
    margin-bottom: 20px;
  }
  .mod_tinline_course_reader .content-column {
    width: auto;
    float: none;
    margin-left: 305px;
  }
}
@media (max-width: 767px) {
  /* mobile-small.css */
  .mobile-only {
    display: block;
  }
  .article-home-news .mod_newslist .layout_latest {
    width: 100%;
  }
  .article-home-news .layout_latest h2 {
    margin-bottom: 12px;
  }
  .article-home-news .layout_latest .image_container {
    display: none;
  }
  .article-home-featured .ce_image .image_container, .article-home-featured .ce_tinline_youtube .content, .article-home-featured .ce_tinline_youtube .content-inner {
    padding: 0;
    text-align: center;
  }
  .page-layout-product #container {
    padding-left: 0;
  }
  #left {
    width: 100%;
    right: 0;
  }
  #left .inside {
    padding-top: 10px;
  }
  #left .left-menu {
    display: none;
  }
  .mod_tinline_course_reader .header {
    margin-bottom: 30px;
    padding-top: 51px;
  }
  .mod_tinline_course_reader .header h1 {
    margin-bottom: 0;
    font-size: 30px;
    line-height: 40px;
  }
  .mod_tinline_course_reader .header h1 + p {
    line-height: 22px;
  }
  .mod_tinline_course_reader .details-column, .mod_tinline_course_reader .content-column {
    width: 100%;
    float: none;
    margin-right: 0;
  }
  .mod_tinline_course_reader .content-column {
    margin-left: 0;
    padding-top: 8px;
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .widget > label, .mod_tinline_course_reader .content-column .subscription-form .formbody .widget > fieldset > legend {
    width: auto;
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .explanation {
    margin-left: 0;
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .submit_container {
    text-align: center;
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .submit {
    margin-left: 0;
  }
  .ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li {
    margin-right: 0;
    margin-bottom: 9px;
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a {
    position: relative;
    text-align: left;
    padding: 0 7px !important;
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li .ce_cfg_tabs_tab_start a {
    border: none !important;
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a .name {
    padding: 10px 15px !important;
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a.active .name {
    border-bottom: 2px solid #ebebeb;
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a:after {
    width: 12px;
    height: 8px;
    right: 21px;
    top: 17px;
    position: absolute;
    background-image: url("../images/mobile/tabs-arrow-down.png");
    background-repeat: no-repeat;
    content: "";
    background-position: 90% center;
    background-size: 12px 8px;
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a.active:after {
    background-image: url("../images/mobile/tabs-arrow-up.png");
  }
  .cfg_tabs_wrapper.mobile .ce_cfg_tabs_tab_start {
    padding-top: 36px;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 0px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-bottom: 2px solid #ebebeb;
    border-left: 1px solid #ebebeb;
  }
  .cfg_tabs_wrapper.mobile .ce_cfg_tabs_tab_start .ce_text {
    text-align: left;
  }
}
/* Grid mixin */
/* Other rules */
.mod_article:after,
.mod_article > .inner:after,
.grid-row:after,
.grid-inner-row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-row {
  margin-left: -15px;
  margin-right: -15px;
}

.mod_article [class*=mod_],
.mod_article [class*=ce_],
.mod_article .hbspt-form {
  padding-left: 15px;
  padding-right: 15px;
}

[class*=col-] {
  float: left;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.mod_article[class*=col-] > * {
  padding-left: 0;
  padding-right: 0;
}

/* Generate grids */
#header .inner,
#container,
#footer .inner {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.col-xs-1 {
  width: 6.25%;
}

.offset-xs-1 {
  padding-left: 6.25% !important;
}

.col-xs-2 {
  width: 12.5%;
}

.offset-xs-2 {
  padding-left: 12.5% !important;
}

.col-xs-3 {
  width: 18.75%;
}

.offset-xs-3 {
  padding-left: 18.75% !important;
}

.col-xs-4 {
  width: 25%;
}

.offset-xs-4 {
  padding-left: 25% !important;
}

.col-xs-5 {
  width: 31.25%;
}

.offset-xs-5 {
  padding-left: 31.25% !important;
}

.col-xs-6 {
  width: 37.5%;
}

.offset-xs-6 {
  padding-left: 37.5% !important;
}

.col-xs-7 {
  width: 43.75%;
}

.offset-xs-7 {
  padding-left: 43.75% !important;
}

.col-xs-8 {
  width: 50%;
}

.offset-xs-8 {
  padding-left: 50% !important;
}

.col-xs-9 {
  width: 56.25%;
}

.offset-xs-9 {
  padding-left: 56.25% !important;
}

.col-xs-10 {
  width: 62.5%;
}

.offset-xs-10 {
  padding-left: 62.5% !important;
}

.col-xs-11 {
  width: 68.75%;
}

.offset-xs-11 {
  padding-left: 68.75% !important;
}

.col-xs-12 {
  width: 75%;
}

.offset-xs-12 {
  padding-left: 75% !important;
}

.col-xs-13 {
  width: 81.25%;
}

.offset-xs-13 {
  padding-left: 81.25% !important;
}

.col-xs-14 {
  width: 87.5%;
}

.offset-xs-14 {
  padding-left: 87.5% !important;
}

.col-xs-15 {
  width: 93.75%;
}

.offset-xs-15 {
  padding-left: 93.75% !important;
}

.col-xs-16 {
  width: 100%;
}

.offset-xs-16 {
  padding-left: 100% !important;
}

@media (min-width: 768px) {
  #header .inner,
  #container,
  #footer .inner {
    width: 100%;
  }
  .col-sm-1 {
    width: 6.25%;
  }
  .offset-sm-1 {
    padding-left: 6.25% !important;
  }
  .col-sm-2 {
    width: 12.5%;
  }
  .offset-sm-2 {
    padding-left: 12.5% !important;
  }
  .col-sm-3 {
    width: 18.75%;
  }
  .offset-sm-3 {
    padding-left: 18.75% !important;
  }
  .col-sm-4 {
    width: 25%;
  }
  .offset-sm-4 {
    padding-left: 25% !important;
  }
  .col-sm-5 {
    width: 31.25%;
  }
  .offset-sm-5 {
    padding-left: 31.25% !important;
  }
  .col-sm-6 {
    width: 37.5%;
  }
  .offset-sm-6 {
    padding-left: 37.5% !important;
  }
  .col-sm-7 {
    width: 43.75%;
  }
  .offset-sm-7 {
    padding-left: 43.75% !important;
  }
  .col-sm-8 {
    width: 50%;
  }
  .offset-sm-8 {
    padding-left: 50% !important;
  }
  .col-sm-9 {
    width: 56.25%;
  }
  .offset-sm-9 {
    padding-left: 56.25% !important;
  }
  .col-sm-10 {
    width: 62.5%;
  }
  .offset-sm-10 {
    padding-left: 62.5% !important;
  }
  .col-sm-11 {
    width: 68.75%;
  }
  .offset-sm-11 {
    padding-left: 68.75% !important;
  }
  .col-sm-12 {
    width: 75%;
  }
  .offset-sm-12 {
    padding-left: 75% !important;
  }
  .col-sm-13 {
    width: 81.25%;
  }
  .offset-sm-13 {
    padding-left: 81.25% !important;
  }
  .col-sm-14 {
    width: 87.5%;
  }
  .offset-sm-14 {
    padding-left: 87.5% !important;
  }
  .col-sm-15 {
    width: 93.75%;
  }
  .offset-sm-15 {
    padding-left: 93.75% !important;
  }
  .col-sm-16 {
    width: 100%;
  }
  .offset-sm-16 {
    padding-left: 100% !important;
  }
  .col-sm-8:nth-child(2n+1) {
    clear: left;
  }
}
@media (min-width: 992px) {
  #header .inner,
  #container,
  #footer .inner {
    width: 980px;
  }
  .col-1 {
    width: 60px;
  }
  .offset-1 {
    padding-left: 60px !important;
  }
  .col-2 {
    width: 120px;
  }
  .offset-2 {
    padding-left: 120px !important;
  }
  .col-3 {
    width: 180px;
  }
  .offset-3 {
    padding-left: 180px !important;
  }
  .col-4 {
    width: 240px;
  }
  .offset-4 {
    padding-left: 240px !important;
  }
  .col-5 {
    width: 300px;
  }
  .offset-5 {
    padding-left: 300px !important;
  }
  .col-6 {
    width: 360px;
  }
  .offset-6 {
    padding-left: 360px !important;
  }
  .col-7 {
    width: 420px;
  }
  .offset-7 {
    padding-left: 420px !important;
  }
  .col-8 {
    width: 480px;
  }
  .offset-8 {
    padding-left: 480px !important;
  }
  .col-9 {
    width: 540px;
  }
  .offset-9 {
    padding-left: 540px !important;
  }
  .col-10 {
    width: 600px;
  }
  .offset-10 {
    padding-left: 600px !important;
  }
  .col-11 {
    width: 660px;
  }
  .offset-11 {
    padding-left: 660px !important;
  }
  .col-12 {
    width: 720px;
  }
  .offset-12 {
    padding-left: 720px !important;
  }
  .col-13 {
    width: 780px;
  }
  .offset-13 {
    padding-left: 780px !important;
  }
  .col-14 {
    width: 840px;
  }
  .offset-14 {
    padding-left: 840px !important;
  }
  .col-15 {
    width: 900px;
  }
  .offset-15 {
    padding-left: 900px !important;
  }
  .col-16 {
    width: 960px;
  }
  .offset-16 {
    padding-left: 960px !important;
  }
  .col-md-1 {
    width: 60px;
  }
  .offset-md-1 {
    padding-left: 60px !important;
  }
  .col-md-2 {
    width: 120px;
  }
  .offset-md-2 {
    padding-left: 120px !important;
  }
  .col-md-3 {
    width: 180px;
  }
  .offset-md-3 {
    padding-left: 180px !important;
  }
  .col-md-4 {
    width: 240px;
  }
  .offset-md-4 {
    padding-left: 240px !important;
  }
  .col-md-5 {
    width: 300px;
  }
  .offset-md-5 {
    padding-left: 300px !important;
  }
  .col-md-6 {
    width: 360px;
  }
  .offset-md-6 {
    padding-left: 360px !important;
  }
  .col-md-7 {
    width: 420px;
  }
  .offset-md-7 {
    padding-left: 420px !important;
  }
  .col-md-8 {
    width: 480px;
  }
  .offset-md-8 {
    padding-left: 480px !important;
  }
  .col-md-9 {
    width: 540px;
  }
  .offset-md-9 {
    padding-left: 540px !important;
  }
  .col-md-10 {
    width: 600px;
  }
  .offset-md-10 {
    padding-left: 600px !important;
  }
  .col-md-11 {
    width: 660px;
  }
  .offset-md-11 {
    padding-left: 660px !important;
  }
  .col-md-12 {
    width: 720px;
  }
  .offset-md-12 {
    padding-left: 720px !important;
  }
  .col-md-13 {
    width: 780px;
  }
  .offset-md-13 {
    padding-left: 780px !important;
  }
  .col-md-14 {
    width: 840px;
  }
  .offset-md-14 {
    padding-left: 840px !important;
  }
  .col-md-15 {
    width: 900px;
  }
  .offset-md-15 {
    padding-left: 900px !important;
  }
  .col-md-16 {
    width: 960px;
  }
  .offset-md-16 {
    padding-left: 960px !important;
  }
  .col-sm-8:nth-child(2n+1) {
    clear: none;
  }
  .col-md-5:nth-child(3n+1) {
    clear: left;
  }
  .col-md-1-3 {
    width: 33.3333%;
  }
}
@media (min-width: 1200px) {
  #header .inner,
  #container,
  #footer .inner {
    width: 1200px;
  }
  .col-1 {
    width: 75px;
  }
  .offset-1 {
    padding-left: 75px !important;
  }
  .col-2 {
    width: 150px;
  }
  .offset-2 {
    padding-left: 150px !important;
  }
  .col-3 {
    width: 225px;
  }
  .offset-3 {
    padding-left: 225px !important;
  }
  .col-4 {
    width: 300px;
  }
  .offset-4 {
    padding-left: 300px !important;
  }
  .col-5 {
    width: 375px;
  }
  .offset-5 {
    padding-left: 375px !important;
  }
  .col-6 {
    width: 450px;
  }
  .offset-6 {
    padding-left: 450px !important;
  }
  .col-7 {
    width: 525px;
  }
  .offset-7 {
    padding-left: 525px !important;
  }
  .col-8 {
    width: 600px;
  }
  .offset-8 {
    padding-left: 600px !important;
  }
  .col-9 {
    width: 675px;
  }
  .offset-9 {
    padding-left: 675px !important;
  }
  .col-10 {
    width: 750px;
  }
  .offset-10 {
    padding-left: 750px !important;
  }
  .col-11 {
    width: 825px;
  }
  .offset-11 {
    padding-left: 825px !important;
  }
  .col-12 {
    width: 900px;
  }
  .offset-12 {
    padding-left: 900px !important;
  }
  .col-13 {
    width: 975px;
  }
  .offset-13 {
    padding-left: 975px !important;
  }
  .col-14 {
    width: 1050px;
  }
  .offset-14 {
    padding-left: 1050px !important;
  }
  .col-15 {
    width: 1125px;
  }
  .offset-15 {
    padding-left: 1125px !important;
  }
  .col-16 {
    width: 1200px;
  }
  .offset-16 {
    padding-left: 1200px !important;
  }
  .col-lg-1 {
    width: 75px;
  }
  .offset-lg-1 {
    padding-left: 75px !important;
  }
  .col-lg-2 {
    width: 150px;
  }
  .offset-lg-2 {
    padding-left: 150px !important;
  }
  .col-lg-3 {
    width: 225px;
  }
  .offset-lg-3 {
    padding-left: 225px !important;
  }
  .col-lg-4 {
    width: 300px;
  }
  .offset-lg-4 {
    padding-left: 300px !important;
  }
  .col-lg-5 {
    width: 375px;
  }
  .offset-lg-5 {
    padding-left: 375px !important;
  }
  .col-lg-6 {
    width: 450px;
  }
  .offset-lg-6 {
    padding-left: 450px !important;
  }
  .col-lg-7 {
    width: 525px;
  }
  .offset-lg-7 {
    padding-left: 525px !important;
  }
  .col-lg-8 {
    width: 600px;
  }
  .offset-lg-8 {
    padding-left: 600px !important;
  }
  .col-lg-9 {
    width: 675px;
  }
  .offset-lg-9 {
    padding-left: 675px !important;
  }
  .col-lg-10 {
    width: 750px;
  }
  .offset-lg-10 {
    padding-left: 750px !important;
  }
  .col-lg-11 {
    width: 825px;
  }
  .offset-lg-11 {
    padding-left: 825px !important;
  }
  .col-lg-12 {
    width: 900px;
  }
  .offset-lg-12 {
    padding-left: 900px !important;
  }
  .col-lg-13 {
    width: 975px;
  }
  .offset-lg-13 {
    padding-left: 975px !important;
  }
  .col-lg-14 {
    width: 1050px;
  }
  .offset-lg-14 {
    padding-left: 1050px !important;
  }
  .col-lg-15 {
    width: 1125px;
  }
  .offset-lg-15 {
    padding-left: 1125px !important;
  }
  .col-lg-16 {
    width: 1200px;
  }
  .offset-lg-16 {
    padding-left: 1200px !important;
  }
  .col-sm-8:nth-child(2n+1),
  .col-md-5:nth-child(3n+1) {
    clear: none;
  }
  .col-lg-4:nth-child(3n+1) {
    clear: left;
  }
}
.formbody .inputs-list, .formbody .inputs-list li,
.hbspt-form .inputs-list,
.hbspt-form .inputs-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.formbody .inputs-list:before, .formbody .inputs-list li:before,
.hbspt-form .inputs-list:before,
.hbspt-form .inputs-list li:before {
  content: none;
}
.formbody label span.mandatory,
.formbody label span.hs-form-required,
.formbody legend span.mandatory,
.formbody legend span.hs-form-required,
.hbspt-form label span.mandatory,
.hbspt-form label span.hs-form-required,
.hbspt-form legend span.mandatory,
.hbspt-form legend span.hs-form-required {
  margin-left: 4px;
}
.formbody .error > label,
.formbody .error > legend,
.hbspt-form .error > label,
.hbspt-form .error > legend {
  color: #d80000;
}
.formbody p.error,
.formbody .hs-error-msgs,
.hbspt-form p.error,
.hbspt-form .hs-error-msgs {
  display: none;
}
.formbody .widget,
.formbody .hs-form-field,
.hbspt-form .widget,
.hbspt-form .hs-form-field {
  margin-bottom: 11px;
}
.formbody .widget:after,
.formbody .hs-form-field:after,
.hbspt-form .widget:after,
.hbspt-form .hs-form-field:after {
  content: "";
  display: table;
  clear: both;
}
.formbody .widget > label,
.formbody .widget > fieldset > legend,
.formbody .hs-form-field > label,
.formbody .hs-form-field > fieldset > legend,
.hbspt-form .widget > label,
.hbspt-form .widget > fieldset > legend,
.hbspt-form .hs-form-field > label,
.hbspt-form .hs-form-field > fieldset > legend {
  width: 225px;
  float: left;
  padding-right: 20px;
  text-align: right;
}
.formbody .widget > label,
.formbody .hs-form-field > label,
.hbspt-form .widget > label,
.hbspt-form .hs-form-field > label {
  padding-top: 10px;
}
.formbody .widget.hs-fieldtype-radio > label,
.formbody .hs-form-field.hs-fieldtype-radio > label,
.hbspt-form .widget.hs-fieldtype-radio > label,
.hbspt-form .hs-form-field.hs-fieldtype-radio > label {
  padding-top: 0;
}
.formbody .widget .location-inputs,
.formbody .hs-form-field .location-inputs,
.hbspt-form .widget .location-inputs,
.hbspt-form .hs-form-field .location-inputs {
  float: left;
}
.formbody .widget .location-inputs:after,
.formbody .hs-form-field .location-inputs:after,
.hbspt-form .widget .location-inputs:after,
.hbspt-form .hs-form-field .location-inputs:after {
  content: "";
  display: table;
  clear: both;
}
.formbody .widget .location-inputs input:first-child,
.formbody .hs-form-field .location-inputs input:first-child,
.hbspt-form .widget .location-inputs input:first-child,
.hbspt-form .hs-form-field .location-inputs input:first-child {
  width: 108px;
  float: left;
  margin-right: 15px;
}
.formbody .widget .location-inputs input:last-child,
.formbody .hs-form-field .location-inputs input:last-child,
.hbspt-form .widget .location-inputs input:last-child,
.hbspt-form .hs-form-field .location-inputs input:last-child {
  width: 307px;
  float: left;
}
.formbody .text,
.formbody .textarea,
.formbody .captcha,
.formbody .select_container,
.formbody .hs-fieldtype-text input,
.formbody .hs-fieldtype-phonenumber input,
.formbody .hs-fieldtype-textarea textarea,
.formbody .hs-fieldtype-select > .input,
.hbspt-form .text,
.hbspt-form .textarea,
.hbspt-form .captcha,
.hbspt-form .select_container,
.hbspt-form .hs-fieldtype-text input,
.hbspt-form .hs-fieldtype-phonenumber input,
.hbspt-form .hs-fieldtype-textarea textarea,
.hbspt-form .hs-fieldtype-select > .input {
  width: 430px;
  height: 40px;
  float: left;
  margin: 0;
  padding: 0 14px;
  background-color: #fff;
  border: 1px solid #d1d1d0;
  border-radius: 0;
  font-size: 14px;
  line-height: 40px;
  font-weight: 300;
  color: #171715;
  -webkit-appearance: none;
}
.formbody .text.error,
.formbody .textarea.error,
.formbody .captcha.error,
.formbody .hs-input.error,
.hbspt-form .text.error,
.hbspt-form .textarea.error,
.hbspt-form .captcha.error,
.hbspt-form .hs-input.error {
  border-color: #d80000 !important;
}
.formbody .text:focus,
.formbody .textarea:focus,
.formbody .captcha:focus,
.formbody .hs-input:focus,
.hbspt-form .text:focus,
.hbspt-form .textarea:focus,
.hbspt-form .captcha:focus,
.hbspt-form .hs-input:focus {
  border-color: #171715;
}
.formbody .textarea,
.formbody .hs-fieldtype-textarea textarea,
.hbspt-form .textarea,
.hbspt-form .hs-fieldtype-textarea textarea {
  height: 141px;
  padding-top: 10px;
  line-height: 18px;
  resize: vertical;
}
.formbody .select_container,
.formbody .hs-fieldtype-select > .input,
.hbspt-form .select_container,
.hbspt-form .hs-fieldtype-select > .input {
  position: relative;
  padding-right: 38px;
  background-image: url("../images/form-select-dropdown.png");
  background-repeat: no-repeat;
  background-position: 97% center;
}
.formbody .select_container select,
.formbody .hs-fieldtype-select > .input select,
.hbspt-form .select_container select,
.hbspt-form .hs-fieldtype-select > .input select {
  position: absolute;
  width: 100%;
  inset: 0;
  font-size: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 14px;
}
.formbody .radio_container,
.formbody .checkbox_container,
.formbody .inputs-list,
.hbspt-form .radio_container,
.hbspt-form .checkbox_container,
.hbspt-form .inputs-list {
  margin: 0;
  padding: 0 0 10px;
  border: 0;
}
.formbody .checkbox_container,
.formbody .hs-fieldtype-checkbox .inputs-list,
.hbspt-form .checkbox_container,
.hbspt-form .hs-fieldtype-checkbox .inputs-list {
  padding-top: 10px;
  padding-bottom: 5px;
}
.formbody .checkbox_container > span,
.formbody .checkbox_container .hs-form-checkbox-display,
.formbody .hs-fieldtype-checkbox .inputs-list > span,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display,
.hbspt-form .checkbox_container > span,
.hbspt-form .checkbox_container .hs-form-checkbox-display,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display {
  position: relative;
  display: block;
  margin-bottom: 5px;
  margin-left: 225px;
}
.formbody .checkbox_container > span input,
.formbody .checkbox_container .hs-form-checkbox-display input,
.formbody .hs-fieldtype-checkbox .inputs-list > span input,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input,
.hbspt-form .checkbox_container > span input,
.hbspt-form .checkbox_container .hs-form-checkbox-display input,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span input,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input {
  position: absolute;
  opacity: 0;
}
.formbody .checkbox_container > span label,
.formbody .checkbox_container > span input + span,
.formbody .checkbox_container .hs-form-checkbox-display label,
.formbody .checkbox_container .hs-form-checkbox-display input + span,
.formbody .hs-fieldtype-checkbox .inputs-list > span label,
.formbody .hs-fieldtype-checkbox .inputs-list > span input + span,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display label,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input + span,
.hbspt-form .checkbox_container > span label,
.hbspt-form .checkbox_container > span input + span,
.hbspt-form .checkbox_container .hs-form-checkbox-display label,
.hbspt-form .checkbox_container .hs-form-checkbox-display input + span,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span label,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span input + span,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display label,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input + span {
  padding-left: 20px;
  background-image: url("../images/form-checkbox.png");
  background-repeat: no-repeat;
  background-position: left 3px;
  cursor: pointer;
}
.formbody .checkbox_container > span input:checked + label,
.formbody .checkbox_container > span input:checked + span,
.formbody .checkbox_container .hs-form-checkbox-display input:checked + label,
.formbody .checkbox_container .hs-form-checkbox-display input:checked + span,
.formbody .hs-fieldtype-checkbox .inputs-list > span input:checked + label,
.formbody .hs-fieldtype-checkbox .inputs-list > span input:checked + span,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:checked + label,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:checked + span,
.hbspt-form .checkbox_container > span input:checked + label,
.hbspt-form .checkbox_container > span input:checked + span,
.hbspt-form .checkbox_container .hs-form-checkbox-display input:checked + label,
.hbspt-form .checkbox_container .hs-form-checkbox-display input:checked + span,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span input:checked + label,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span input:checked + span,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:checked + label,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:checked + span {
  background-image: url("../images/form-checkbox-active.png");
}
.formbody .checkbox_container > span input:focus + label:before,
.formbody .checkbox_container > span input:focus + span:before,
.formbody .checkbox_container .hs-form-checkbox-display input:focus + label:before,
.formbody .checkbox_container .hs-form-checkbox-display input:focus + span:before,
.formbody .hs-fieldtype-checkbox .inputs-list > span input:focus + label:before,
.formbody .hs-fieldtype-checkbox .inputs-list > span input:focus + span:before,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:focus + label:before,
.formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:focus + span:before,
.hbspt-form .checkbox_container > span input:focus + label:before,
.hbspt-form .checkbox_container > span input:focus + span:before,
.hbspt-form .checkbox_container .hs-form-checkbox-display input:focus + label:before,
.hbspt-form .checkbox_container .hs-form-checkbox-display input:focus + span:before,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span input:focus + label:before,
.hbspt-form .hs-fieldtype-checkbox .inputs-list > span input:focus + span:before,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:focus + label:before,
.hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display input:focus + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 11px;
  height: 11px;
  border: 1px solid #171715;
}
.formbody .radio_container li,
.formbody .hs-fieldtype-radio .inputs-list li,
.hbspt-form .radio_container li,
.hbspt-form .hs-fieldtype-radio .inputs-list li {
  display: inline-block;
}
.formbody .radio_container > span,
.formbody .radio_container .hs-form-radio-display,
.formbody .hs-fieldtype-radio .inputs-list > span,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display,
.hbspt-form .radio_container > span,
.hbspt-form .radio_container .hs-form-radio-display,
.hbspt-form .hs-fieldtype-radio .inputs-list > span,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display {
  position: relative;
  display: inline-block;
  margin-right: 48px;
}
.formbody .radio_container > span input,
.formbody .radio_container .hs-form-radio-display input,
.formbody .hs-fieldtype-radio .inputs-list > span input,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display input,
.hbspt-form .radio_container > span input,
.hbspt-form .radio_container .hs-form-radio-display input,
.hbspt-form .hs-fieldtype-radio .inputs-list > span input,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display input {
  position: absolute;
  opacity: 0;
}
.formbody .radio_container > span label,
.formbody .radio_container > span input + span,
.formbody .radio_container .hs-form-radio-display label,
.formbody .radio_container .hs-form-radio-display input + span,
.formbody .hs-fieldtype-radio .inputs-list > span label,
.formbody .hs-fieldtype-radio .inputs-list > span input + span,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display label,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display input + span,
.hbspt-form .radio_container > span label,
.hbspt-form .radio_container > span input + span,
.hbspt-form .radio_container .hs-form-radio-display label,
.hbspt-form .radio_container .hs-form-radio-display input + span,
.hbspt-form .hs-fieldtype-radio .inputs-list > span label,
.hbspt-form .hs-fieldtype-radio .inputs-list > span input + span,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display label,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display input + span {
  display: inline-block;
  padding-left: 20px;
  background-image: url("../images/form-radio.png");
  background-repeat: no-repeat;
  background-position: left 4px;
  cursor: pointer;
}
.formbody .radio_container > span input:checked + label,
.formbody .radio_container > span input:checked + span,
.formbody .radio_container .hs-form-radio-display input:checked + label,
.formbody .radio_container .hs-form-radio-display input:checked + span,
.formbody .hs-fieldtype-radio .inputs-list > span input:checked + label,
.formbody .hs-fieldtype-radio .inputs-list > span input:checked + span,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:checked + label,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:checked + span,
.hbspt-form .radio_container > span input:checked + label,
.hbspt-form .radio_container > span input:checked + span,
.hbspt-form .radio_container .hs-form-radio-display input:checked + label,
.hbspt-form .radio_container .hs-form-radio-display input:checked + span,
.hbspt-form .hs-fieldtype-radio .inputs-list > span input:checked + label,
.hbspt-form .hs-fieldtype-radio .inputs-list > span input:checked + span,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:checked + label,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:checked + span {
  background-image: url("../images/form-radio-active.png");
}
.formbody .radio_container > span input:focus + label:before,
.formbody .radio_container > span input:focus + span:before,
.formbody .radio_container .hs-form-radio-display input:focus + label:before,
.formbody .radio_container .hs-form-radio-display input:focus + span:before,
.formbody .hs-fieldtype-radio .inputs-list > span input:focus + label:before,
.formbody .hs-fieldtype-radio .inputs-list > span input:focus + span:before,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:focus + label:before,
.formbody .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:focus + span:before,
.hbspt-form .radio_container > span input:focus + label:before,
.hbspt-form .radio_container > span input:focus + span:before,
.hbspt-form .radio_container .hs-form-radio-display input:focus + label:before,
.hbspt-form .radio_container .hs-form-radio-display input:focus + span:before,
.hbspt-form .hs-fieldtype-radio .inputs-list > span input:focus + label:before,
.hbspt-form .hs-fieldtype-radio .inputs-list > span input:focus + span:before,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:focus + label:before,
.hbspt-form .hs-fieldtype-radio .inputs-list .hs-form-radio-display input:focus + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 12px;
  height: 12px;
  border: 1px solid #171715;
  border-radius: 50%;
}
.formbody .captcha,
.hbspt-form .captcha {
  width: 75px;
  margin-right: 8px;
  text-align: center;
}
.formbody .captcha_text,
.hbspt-form .captcha_text {
  font-weight: bold;
}
.formbody .submit,
.formbody .hs-button.primary,
.hbspt-form .submit,
.hbspt-form .hs-button.primary {
  display: inline-block;
  margin-left: 225px;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border: none;
  border-radius: 20px;
  font-family: "Soho Gothic W01", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  transition: background-color 0.2s;
  background-color: #df000e;
  cursor: pointer;
  -webkit-appearance: none;
}
.formbody .submit:hover,
.formbody .hs-button.primary:hover,
.hbspt-form .submit:hover,
.hbspt-form .hs-button.primary:hover {
  background-color: #181716;
  color: #fff;
}
.formbody .explanation,
.formbody .legal-consent-container,
.hbspt-form .explanation,
.hbspt-form .legal-consent-container {
  margin-left: 225px;
  font-size: 11px;
  line-height: 15px;
  color: #bcbcbc;
}
.formbody .explanation p,
.formbody .legal-consent-container p,
.hbspt-form .explanation p,
.hbspt-form .legal-consent-container p {
  margin-bottom: 0;
}

.contact-form .formbody,
.contact-form .hbspt-form {
  padding-top: 36px;
}
.contact-form .widget-textarea,
.contact-form .hs-fieldtype-textarea {
  margin-bottom: 4px;
}
.contact-form .widget-explanation,
.contact-form .legal-consent-container {
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .formbody .text,
  .formbody .textarea,
  .formbody .captcha,
  .formbody .select_container,
  .formbody .hs-fieldtype-text input,
  .formbody .hs-fieldtype-phonenumber input,
  .formbody .hs-fieldtype-textarea textarea,
  .formbody .hs-fieldtype-select > .input,
  .hbspt-form .text,
  .hbspt-form .textarea,
  .hbspt-form .captcha,
  .hbspt-form .select_container,
  .hbspt-form .hs-fieldtype-text input,
  .hbspt-form .hs-fieldtype-phonenumber input,
  .hbspt-form .hs-fieldtype-textarea textarea,
  .hbspt-form .hs-fieldtype-select > .input {
    width: 100%;
  }
  .formbody .widget,
  .formbody .hs-form-field,
  .hbspt-form .widget,
  .hbspt-form .hs-form-field {
    margin-bottom: 17px;
  }
  .formbody .widget > label,
  .formbody .widget > fieldset > legend,
  .formbody .hs-form-field > label,
  .formbody .hs-form-field > fieldset > legend,
  .hbspt-form .widget > label,
  .hbspt-form .widget > fieldset > legend,
  .hbspt-form .hs-form-field > label,
  .hbspt-form .hs-form-field > fieldset > legend {
    width: auto;
    float: none;
    display: block;
    margin-bottom: 7px;
    padding-top: 0;
    text-align: left;
  }
  .formbody .widget .location-inputs,
  .formbody .hs-form-field .location-inputs,
  .hbspt-form .widget .location-inputs,
  .hbspt-form .hs-form-field .location-inputs {
    position: relative;
    float: none;
    padding-left: 103px;
  }
  .formbody .widget .location-inputs input:first-child,
  .formbody .hs-form-field .location-inputs input:first-child,
  .hbspt-form .widget .location-inputs input:first-child,
  .hbspt-form .hs-form-field .location-inputs input:first-child {
    width: 88px;
    left: 0;
    top: 0;
    position: absolute;
    float: none;
  }
  .formbody .widget .location-inputs input:last-child,
  .formbody .hs-form-field .location-inputs input:last-child,
  .hbspt-form .widget .location-inputs input:last-child,
  .hbspt-form .hs-form-field .location-inputs input:last-child {
    width: 100%;
    float: none;
  }
  .formbody .checkbox_container > span,
  .formbody .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display,
  .hbspt-form .checkbox_container > span,
  .hbspt-form .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display {
    margin-left: 0;
  }
  .formbody .submit,
  .formbody .hs-button.primary,
  .hbspt-form .submit,
  .hbspt-form .hs-button.primary {
    margin-left: 0;
  }
  .formbody .submit_container,
  .hbspt-form .submit_container {
    text-align: center;
  }
  .formbody .explanation,
  .formbody .legal-consent-container,
  .hbspt-form .explanation,
  .hbspt-form .legal-consent-container {
    margin-left: 0;
  }
}
body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: rgb(30, 30, 30);
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

@supports (padding: max(0px)) {
  .fancybox-caption {
    padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left));
  }
}
.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
  @supports (padding: max(0px)) {
    .fancybox-caption {
      padding-left: max(12px, env(safe-area-inset-left));
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
}
/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  left: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  bottom: 100px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
#header {
  min-height: 150px;
}
@media (max-width: 991px) {
  #header {
    margin-bottom: 0;
    min-height: 75px;
  }
}
#header .mod_article {
  margin-bottom: 115px;
}
@media (max-width: 991px) {
  #header .mod_article {
    margin-bottom: 15px;
  }
}

.header {
  display: flex;
  align-items: stretch;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  height: 85px;
  font-size: 13px;
}
@media (max-width: 991px) {
  .header {
    height: 78px;
    padding: 0 20px;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.header__menu {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 991px) {
  .header__menu--desktop {
    display: none;
  }
}
.header__menu--mobile {
  display: none;
}
@media (max-width: 991px) {
  .header__menu--mobile {
    display: flex;
    align-items: center;
  }
}
.header__navigation li::before {
  display: none;
}
.header__navigation ul.level_1 {
  display: flex;
}
.header__navigation ul.level_1 > li {
  position: relative;
  white-space: nowrap;
}
.header__navigation ul.level_1 > li.submenu::after {
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - 12px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 11px 12px;
  border-color: transparent transparent #fff transparent;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.2s;
}
.header__navigation ul.level_1 > li.submenu:hover::after {
  opacity: 1;
}
.header__navigation ul.level_1 > li.submenu:hover > div.level_2-wrapper {
  opacity: 1;
  pointer-events: auto;
}
.header__navigation ul.level_1 > li.submenu.last > div.level_2-wrapper {
  left: auto;
  right: 0;
}
.header__navigation ul.level_1 > li.submenu.last > div.level_2-wrapper.has-image {
  justify-content: flex-end;
}
.header__navigation ul.level_1 > li:not(:last-child) {
  margin-right: 18px;
}
.header__navigation ul.level_1 > li > a,
.header__navigation ul.level_1 > li > strong {
  position: relative;
  z-index: 5;
  display: block;
  padding: 5px 5px 24px 5px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #171715;
  transition: color 0.2s;
}
.header__navigation ul.level_1 > li > a::before,
.header__navigation ul.level_1 > li > strong::before {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  position: absolute;
  left: 50%;
  bottom: 21px;
  background: #e72835;
  transition: all 0.2s;
}
.header__navigation ul.level_1 > li > a:hover,
.header__navigation ul.level_1 > li > a.trail,
.header__navigation ul.level_1 > li > strong {
  color: #df000e;
}
.header__navigation ul.level_1 > li > a:hover::before,
.header__navigation ul.level_1 > li > a.trail::before,
.header__navigation ul.level_1 > li > strong::before {
  left: 0;
  width: 100%;
}
.header__navigation div.level_2-wrapper {
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  padding-top: 11px;
  transition: opacity 0.2s;
  opacity: 0;
  pointer-events: none;
  z-index: 5;
}
.header__navigation div.level_2-wrapper.has-image {
  display: flex;
  left: -125px;
  min-width: 525px;
}
.header__navigation div.level_2-wrapper.has-image .header-navigation-image {
  display: flex;
  margin: 0;
}
.header__navigation div.level_2-wrapper.has-image .header-navigation-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header__navigation ul.level_2 {
  min-width: 200px;
  padding: 35px;
  background-color: #fff;
  box-shadow: 0 0 51px 0 rgba(0, 0, 0, 0.2);
  white-space: normal;
}
.header__navigation ul.level_2.has-image {
  min-height: 225px;
  padding-right: 50px;
}
.header__navigation ul.level_2 > li:not(:last-child) {
  margin-bottom: 15px;
}
.header__navigation ul.level_2 > li > a,
.header__navigation ul.level_2 > li > strong {
  font-weight: normal;
  text-decoration: none;
  color: #171715;
  transition: color 0.2s;
}
.header__navigation ul.level_2 > li > a:hover,
.header__navigation ul.level_2 > li > strong {
  color: #df000e;
}
.header__navigation ul.level_2 > li ul {
  display: none;
}
.header__search {
  margin-left: 19px;
  padding-bottom: 26px;
}
.header__search::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  margin-left: -2px;
  border-style: solid;
  border-width: 0 12px 11px 12px;
  border-color: transparent transparent #fff transparent;
  z-index: 6;
  opacity: 0;
  transition: opacity 0.2s;
}
body.search-content-active .header__search::before {
  opacity: 1;
}
.header__search-trigger-link {
  display: block;
  width: 20px;
  height: 20px;
  background: url("../../images/header-search.svg") no-repeat center center;
  background-size: 16px 16px;
  text-indent: -9999px;
  text-decoration: none;
  cursor: pointer;
}
body.search-content-active .header__search-trigger-link {
  opacity: 0.5;
}
.header__search-content-wrapper {
  position: absolute;
  top: 96px;
  left: 15px;
  right: 15px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  z-index: 5;
}
@media (max-width: 991px) {
  .header__search-content-wrapper {
    top: 89px;
  }
}
body.search-content-active .header__search-content-wrapper {
  opacity: 1;
  pointer-events: auto;
}
.header__search-text {
  width: 100%;
  padding: 0 25px;
  font-size: 24px;
  font-weight: 300;
  color: #171715;
  border: none;
  border-radius: 0;
  background: #fff;
  box-shadow: 0 0 51px 0 rgba(0, 0, 0, 0.2);
  height: 69px;
  line-height: 69px;
  -webkit-appearance: none;
}
.header__search-submit {
  display: none;
}
.header__user {
  margin-left: 29px;
}
.header__login {
  position: relative;
  padding-bottom: 19px;
}
.header__login-trigger-link {
  display: block;
  height: 30px;
  line-height: 30px;
  padding: 0 18px;
  border-radius: 15px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #87888b;
  transition: background 0.2s;
}
.header__login-trigger-link:hover {
  background-color: #df000e;
  color: #fff;
}
.header__mobile-menu .mobile_menu_trigger {
  width: 26px;
  height: 19px;
  background: url("../../images/mobile-menu-open.svg") no-repeat;
}
.header__mobile-menu .mobile_menu_trigger.active {
  margin-top: 1px;
  margin-right: 1px;
  width: 21px;
  height: 21px;
  background-image: url("../../images/mobile-menu-close.svg");
}

.mobile_menu .inner {
  padding: 0 20px 40px 20px;
  background-color: #fff;
}
.mobile_menu__header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 78px;
}
.mobile_menu__header .header__search-trigger-link {
  background-size: 20px 20px;
}
.mobile_menu__header .header__search {
  padding-bottom: 24px;
}
.mobile_menu__header .header__search-text {
  font-size: 18px;
}
.mobile_menu__header .header__user {
  margin-left: 24px;
}
.mobile_menu__header .header__user .header__login-trigger-link {
  font-weight: 700;
}
.mobile_menu__header .header__user .header__login-content-wrapper {
  width: calc(75vw - 40px);
}
.mobile_menu__header .header__user .header__login-content {
  padding: 20px;
}
.mobile_menu__header .header__user .header__login-content table table,
.mobile_menu__header .header__user .header__login-content table tbody,
.mobile_menu__header .header__user .header__login-content table tr,
.mobile_menu__header .header__user .header__login-content table td {
  display: block;
  padding: 0;
}
.mobile_menu__header .header__user .header__login-content table tr {
  margin-bottom: 15px;
}
.mobile_menu__header .header__user .header__login-content table td:first-child {
  margin-bottom: 5px;
  padding-right: 0;
  text-align: left;
}
.mobile_menu__header .header__user .header__login-content .row_2 .col_0,
.mobile_menu__header .header__user .header__login-content .row_3 .col_0 {
  display: none;
}
.mobile_menu__header .header__user .formbody .captcha, .mobile_menu__header .header__user .formbody .select_container, .mobile_menu__header .header__user .formbody .text, .mobile_menu__header .header__user .formbody .textarea {
  width: 100%;
  float: none;
}
.mobile_menu__header .header__user .formbody .submit_container {
  float: none;
  margin-bottom: 15px;
}
.mobile_menu .mod_navigation {
  padding: 28px 12px;
}
.mobile_menu .mod_navigation li::before {
  display: none;
}
.mobile_menu .mod_navigation a,
.mobile_menu .mod_navigation strong {
  display: block;
  color: #171715;
  font-size: 18px;
  line-height: 35px;
  text-decoration: none;
  font-weight: normal;
  transition: color 0.2s;
}
.mobile_menu .mod_navigation a:hover,
.mobile_menu .mod_navigation a.trail,
.mobile_menu .mod_navigation strong {
  color: #e72835;
}
.mobile_menu .mod_navigation ul.level_1 > li {
  margin-bottom: 20px;
}
.mobile_menu .mod_navigation ul.level_1 > li > a,
.mobile_menu .mod_navigation ul.level_1 > li > strong {
  font-weight: 700;
  text-transform: uppercase;
}
.mobile_menu .mod_navigation ul.level_2 {
  padding-left: 20px;
}
.mobile_menu .mod_navigation ul.level_3 {
  padding-left: 20px;
}

.image-text {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 991px) {
  .image-text {
    margin-top: 60px;
    margin-bottom: 60px;
    flex-direction: column;
    justify-content: normal;
  }
}
.image-text__headline {
  margin-bottom: 10px;
}
.image-text__content {
  max-width: 600px;
  padding: 0 15px;
}
@media (max-width: 991px) {
  .image-text__content {
    padding: 0 10px;
    order: 1;
  }
}
.image-text__main-image {
  margin-bottom: 0;
  max-width: 525px;
  padding: 0 15px;
}
@media (max-width: 991px) {
  .image-text__main-image {
    margin-bottom: 34px;
    padding: 0 10px;
  }
}
.image-text__description {
  font-size: 14px;
  line-height: 24px;
}
.image-text__image {
  margin: 0;
}
.image-text__image:not(:last-child) {
  margin-right: 20px;
}

.interest-bar {
  margin: 90px -50vw;
  padding: 75px 0;
  background-color: #e72835;
  color: #fff;
}
@media (max-width: 991px) {
  .interest-bar {
    margin: 30px -15px;
    padding: 40px 0 20px 0;
  }
}
.interest-bar__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
@media (max-width: 1199px) {
  .interest-bar__container {
    max-width: 980px;
  }
}
@media (max-width: 991px) {
  .interest-bar__container {
    max-width: 100%;
  }
}
.interest-bar__row {
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .interest-bar__row {
    flex-direction: column;
    align-items: normal;
  }
}
.interest-bar__title {
  font-size: 60px;
  line-height: 1;
}
@media (max-width: 991px) {
  .interest-bar__title {
    margin-bottom: 30px;
    font-size: 50px;
    text-align: center;
  }
}
.interest-bar__text {
  display: flex;
  align-items: center;
  padding-left: 64px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 39px 39px;
  font-size: 14px;
  line-height: 24px;
  min-height: 40px;
}
@media (max-width: 991px) {
  .interest-bar__text {
    justify-content: center;
    margin-bottom: 30px;
    background-position: center top;
    padding-left: 0;
    padding-top: 50px;
    text-align: center;
  }
}
.interest-bar__text:not(:last-child) {
  margin-bottom: 22px;
}
@media (max-width: 991px) {
  .interest-bar__text:not(:last-child) {
    margin-bottom: 42px;
  }
}
.interest-bar__text--phone {
  background-image: url("../../images/icon-phone-white-circle.svg");
}
.interest-bar__text--newsletter {
  background-image: url("../../images/icon-newsletter-white-circle.svg");
}
.interest-bar__text p {
  margin: 0;
}
.interest-bar__text a {
  color: #fff;
}

div[class*=ce_rsce_intro_] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ce_rsce_intro_support,
.ce_rsce_intro_text {
  background-color: #f9f9f9;
}
@media (max-width: 991px) {
  .ce_rsce_intro_support,
  .ce_rsce_intro_text {
    background-color: transparent;
  }
}

.intro-wrapper {
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .intro-wrapper {
    padding: 0 40px;
  }
}

.intro {
  display: flex;
  padding: 0 15px;
  min-height: 670px;
}
@media (max-width: 991px) {
  .intro {
    flex-wrap: wrap;
    min-height: auto;
  }
}
.intro__image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  height: 670px;
  width: 670px;
}
@media (max-width: 991px) {
  .intro__image {
    margin: 0 auto 75px auto;
    height: 375px;
    width: 375px;
  }
}
.intro__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.intro__map {
  height: 670px;
  width: 670px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 991px) {
  .intro__map {
    margin: 0 auto 75px auto;
    height: 375px;
    width: 375px;
  }
}
.intro__mapcontent {
  height: 100%;
}
.intro__headline {
  margin-bottom: 30px;
}
.intro__content {
  max-width: 345px;
  padding: 94px 0 24px 0;
  margin-left: 90px;
}
@media (max-width: 991px) {
  .intro__content {
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
}
.intro__lead, .intro__address, .intro__contact, .intro__opening-hours {
  font-size: 18px;
  line-height: 30px;
}
.intro__address {
  margin-bottom: 20px;
}
.intro__contact {
  margin-bottom: 40px;
}
.intro__description {
  margin-top: 30px;
  font-size: 14px;
  line-height: 24px;
}
.intro__small-image {
  margin: 58px 0 0 0;
}
@media (max-width: 991px) {
  .intro__small-image {
    margin-top: 10px;
  }
}
.intro__support {
  display: flex;
  flex-wrap: wrap;
  margin-top: 45px;
}
.intro__support-link {
  flex-grow: 1;
  display: block;
  margin: 20px;
  padding-top: 45px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 23px auto;
  color: #171715;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}
.intro__support-link:hover {
  text-decoration: underline;
}
.intro__support-link--phone {
  background-image: url("../../images/icon-phone.svg");
}
.intro__support-link--email {
  background-image: url("../../images/icon-email.svg");
}
.intro--image, .intro--contact {
  justify-content: center;
}
@media (max-width: 991px) {
  .intro--image, .intro--contact {
    margin-bottom: 54px;
    justify-content: normal;
    padding-left: 0;
    padding-right: 0;
  }
}
.intro--image .intro__content, .intro--contact .intro__content {
  max-width: 670px;
  padding-right: 325px;
}
@media (max-width: 991px) {
  .intro--image .intro__content, .intro--contact .intro__content {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.intro--support, .intro--text {
  padding: 84px 0 94px 0;
  min-height: auto;
}
@media (max-width: 991px) {
  .intro--support, .intro--text {
    margin-bottom: 54px;
    padding: 30px 0 0 0;
  }
}
.intro--support .intro__headline, .intro--text .intro__headline {
  margin-bottom: 0;
}
.intro--support .intro__content, .intro--text .intro__content {
  margin-left: 0;
  padding: 30px 0 20px 0;
  max-width: 100%;
}
.intro--support [class*=col-], .intro--text [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}
.intro--support .intro__content {
  padding-bottom: 0;
}

.link-boxes__items {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .link-boxes__items {
    padding-left: 75px;
  }
}
.link-boxes__items > * {
  margin-bottom: 30px;
}
.link-boxes__item {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 25px;
  background-color: #f2f4f6;
  border-bottom: 1px solid #dcdedf;
  border-top: 1px solid #dcdedf;
}
.link-boxes__header {
  margin-bottom: 15px;
  padding-left: 60px;
  min-height: 55px;
  background: url("../../images/icon-link-box.svg") no-repeat top left/auto 50px;
}
.link-boxes__subtitle {
  margin: 5px 0 2px;
  font-size: 14px;
  line-height: 1;
}
.link-boxes__title {
  font-size: 20px;
  font-family: "Soho Gothic W01", sans-serif;
  font-weight: 500;
  line-height: 1.25;
}
.link-boxes__image {
  margin-bottom: 10px;
  height: 70px;
}
.link-boxes__image img {
  width: auto;
  height: 100%;
}
.link-boxes__text {
  margin-bottom: 36px;
}
.link-boxes__link-wrapper {
  margin-top: auto;
  text-align: center;
}
.link-boxes__link {
  display: inline-block;
  min-width: 220px;
  text-align: center;
  height: 42px;
  line-height: 38px;
  padding: 0 20px;
  border-radius: 20px;
  border: 2px solid #181716;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #181716;
  transition: all 0.2s;
}
.link-boxes__link:hover {
  color: #fff;
  background-color: #181716;
}

.product-features {
  max-width: 880px;
  margin: 0 auto 50px auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 991px) {
  .product-features {
    max-width: 100%;
    margin-bottom: 30px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.product-features__items {
  display: flex;
  flex-wrap: wrap;
}
.product-features__items--hidden {
  display: none;
}
.product-features__item {
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  .product-features__item {
    width: 33.3333%;
  }
}
@media (max-width: 767px) {
  .product-features__item {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .product-features__item {
    width: 100%;
  }
}
.product-features__item-title {
  font-weight: 500;
  font-size: 18px;
}
.product-features__more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.product-features__more-link {
  display: block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #181716;
  transition: background-color 0.2s;
}
.product-features__more-link:hover {
  background-color: #df000e;
  color: #fff;
}
.product-features__link-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 14px;
}
.product-features__link {
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  color: #181716;
  transition: color 0.2s;
}
.product-features__link:hover {
  color: #df000e;
}

.product-teaser {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 991px) {
  .product-teaser {
    flex-direction: column;
    justify-content: normal;
  }
}
.product-teaser:nth-child(even) .product-teaser__content {
  order: 2;
}
.product-teaser__headline {
  margin-bottom: 10px;
}
.product-teaser__content {
  max-width: 450px;
  padding: 0 15px;
}
@media (max-width: 991px) {
  .product-teaser__content {
    padding: 0 10px;
    order: 1;
  }
}
.product-teaser__main-image {
  margin-bottom: 0;
  max-width: 675px;
  padding: 0 15px;
}
@media (max-width: 991px) {
  .product-teaser__main-image {
    margin-bottom: 34px;
    padding: 0 10px;
  }
}
.product-teaser__description {
  font-size: 14px;
  line-height: 24px;
}
.product-teaser__images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.product-teaser__image {
  margin: 0 0 20px 0;
}
.product-teaser__image-caption {
  margin-top: 5px;
  font-size: 13px;
}
.product-teaser__image-caption-link {
  padding-left: 16px;
  background-position: left;
}
.product-teaser__image:not(:last-child) {
  margin-right: 20px;
}

.product-videos {
  max-width: 880px;
  margin: 0 auto 50px auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 1199px) {
  .product-videos {
    max-width: 100%;
    margin-bottom: 30px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.product-videos__items {
  display: flex;
  flex-wrap: wrap;
}
.product-videos__item {
  display: block;
  margin-bottom: 50px;
  font-weight: 500;
  text-decoration: none;
  color: #181716;
  transition: color 0.2s;
}
@media (max-width: 1199px) {
  .product-videos__item {
    width: 33.3333%;
  }
}
@media (max-width: 767px) {
  .product-videos__item {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .product-videos__item {
    width: 100%;
  }
}
.product-videos__item:hover {
  color: #df000e;
}
.product-videos__item-title {
  font-weight: 500;
  font-size: 18px;
}
.product-videos__item-image {
  position: relative;
  border: 1px solid #d6d7d7;
}
.product-videos__item-image::before {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: block;
  width: 35px;
  height: 35px;
  background: url("../../images/video-play.svg") no-repeat;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.44);
  border-radius: 50%;
  pointer-events: none;
}

.ce_rsce_slider {
  height: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.slider {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background: url("../../images/home-background.svg") no-repeat center;
  background-size: cover;
  overflow: hidden;
}
@media (max-width: 991px) {
  .slider {
    padding: 60px 30px 46px 30px;
    flex-direction: column;
    background-image: url("../../images/home-background-mobile.svg");
  }
}
.slider__image {
  width: 35vw;
  position: absolute;
  top: 0;
  left: calc(50% - 17.4vw);
  pointer-events: none;
  z-index: -1;
}
@media (min-height: 50vw) {
  .slider__image {
    top: auto;
  }
}
@media (max-width: 991px) {
  .slider__image {
    width: 358px;
    left: calc(50% - 179px);
    top: calc(50% - 254px);
  }
}
.slider__image::before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 134%;
  background: url("../../images/home-chameleon-2022.png") no-repeat center;
  background-size: cover;
}
@media (max-width: 991px) {
  .slider__image::before {
    background-image: url("../../images/home-chameleon-mobile-2022.png");
  }
}
.slider__box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  color: #fff;
}
@media (max-width: 991px) {
  .slider__box {
    width: 100%;
    margin-bottom: 60px;
  }
}
@media (max-width: 991px) {
  .slider__box--2 {
    margin-bottom: 0;
  }
}
.slider__box--2 .slider__box-link {
  background-color: #e72835;
}
.slider__box--2 .slider__box-link:hover {
  background-color: #181716;
  color: #fff;
}
.slider__box-subtitle {
  margin-bottom: 46px;
  font-size: 24px;
}
@media (max-width: 991px) {
  .slider__box-subtitle {
    display: none;
  }
}
.slider__box-title {
  margin-bottom: 33px;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
@media (max-width: 991px) {
  .slider__box-title {
    margin-bottom: 17px;
    font-size: 24px;
  }
}
.slider__box-description {
  margin-bottom: 34px;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
}
@media (max-width: 991px) {
  .slider__box-description {
    margin-bottom: 39px;
    font-size: 14px;
    line-height: 20px;
  }
}
.slider__box-link {
  display: block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #181716;
  transition: background-color 0.2s;
}
@media (max-width: 991px) {
  .slider__box-link {
    min-width: 170px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
  }
}
.slider__box-link:hover {
  background-color: #df000e;
  color: #fff;
}
.slider__hint {
  display: none;
}
@media (min-width: 992px) {
  .slider__hint {
    display: block;
    position: absolute;
    left: calc(50% - 100px);
    top: 63%;
  }
}
.slider__hint-tooltip {
  position: absolute;
  left: calc(-50% + 20px);
  bottom: calc(100% + 7px);
  pointer-events: none;
  opacity: 0;
  transform: translateX(calc(-50% + 20px));
  transition: opacity 0.2s;
}
.slider__hint-tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: calc(50% - 9px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 0 9px;
  border-color: #fff transparent transparent transparent;
  z-index: 6;
}
.slider__hint-tooltip-content {
  min-width: 314px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}
.slider__hint-tooltip-headline {
  margin-bottom: 5px;
  font-size: 14px;
  font-style: italic;
}
.slider__hint-tooltip-text {
  color: #87888b;
  font-size: 12px;
  line-height: 1.5;
  font-style: italic;
}
.slider__hint:hover .slider__hint-tooltip {
  opacity: 1;
}
.slider__hint-pulsar {
  display: block;
  width: 40px;
  height: 40px;
  cursor: help;
}
.slider__hint-pulsar-static {
  background: #fff;
  border-radius: 50px;
}
.slider__hint-pulsar-pulse, .slider__hint-pulsar-static {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.slider__hint-pulsar-pulse {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #fff;
  animation: onboard-hotspot-pulse 2s infinite;
  animation-fill-mode: forwards;
}
@keyframes onboard-hotspot-pulse {
  0% {
    width: 10px;
    height: 10px;
  }
  to {
    width: 40px;
    height: 40px;
    opacity: 0;
  }
}

.ce_tinline_anniversary ul {
  display: grid;
  grid-template: 1fr/1fr;
  grid-gap: 30px;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 600px) {
  .ce_tinline_anniversary ul {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 992px) {
  .ce_tinline_anniversary ul {
    grid-template-columns: repeat(3, 1fr);
  }
}
.ce_tinline_anniversary li {
  position: relative;
  margin: 0;
  padding: 0;
  font-weight: 500;
  aspect-ratio: 1/1;
}
.ce_tinline_anniversary li:before {
  content: none;
}
.ce_tinline_anniversary a {
  display: block;
  color: inherit;
  text-decoration: none;
}
.ce_tinline_anniversary figure {
  margin: 0;
}
.ce_tinline_anniversary img {
  width: 100%;
}
.ce_tinline_anniversary .latest {
  aspect-ratio: unset;
  grid-column: 1/-1;
}
.ce_tinline_anniversary .latest .title {
  padding: 30px;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}
.ce_tinline_anniversary .open .number,
.ce_tinline_anniversary .latest .number {
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 10;
  width: 44px;
  height: 56px;
  font-size: 24px;
  line-height: 56px;
  text-align: center;
  color: #fff;
  background: #df000e;
}
.ce_tinline_anniversary .open figure:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgb(136, 4, 11);
  background: linear-gradient(150deg, rgb(136, 4, 11) 26%, rgb(138, 0, 58) 60%);
  mix-blend-mode: multiply;
  z-index: 2;
  transition: opacity 0.3s ease;
}
.ce_tinline_anniversary .open .title {
  position: absolute;
  inset: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  transition: opacity 0.3s ease;
}
.ce_tinline_anniversary .open:hover figure:before,
.ce_tinline_anniversary .open:hover .title {
  opacity: 0;
}
.ce_tinline_anniversary .closed {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ce_tinline_anniversary .closed.dark {
  background: #c9111e;
}
.ce_tinline_anniversary .closed.medium {
  background: #e72835;
}
.ce_tinline_anniversary .closed.light {
  background: #fd3a47;
}
.ce_tinline_anniversary .closed .number {
  font-size: 48px;
  color: #fff;
}

.ce_hyperlink.anniversary {
  position: absolute;
  top: -5px;
  right: calc(50vw - 500px);
  z-index: 2;
}
.ce_hyperlink.anniversary a {
  display: block;
  width: 240px;
  height: 170px;
  background: url("../../images/anniversary.svg") 0 0/contain no-repeat;
  text-indent: -999em;
}
@media (max-width: 991px) {
  .ce_hyperlink.anniversary {
    right: -20px;
  }
}

.home-newslist-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
@media (max-width: 991px) {
  .home-newslist-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.home-newslist {
  display: flex;
  justify-content: space-between;
  max-width: 870px;
  padding: 15px;
  background: #fff;
}
@media (max-width: 991px) {
  .home-newslist {
    padding: 20px;
    flex-wrap: wrap;
    max-width: none;
  }
}
.home-newslist__article {
  display: flex;
  width: calc(50% - 30px);
  padding-right: 40px;
}
@media (max-width: 991px) {
  .home-newslist__article {
    width: 100%;
    padding-right: 0;
  }
  .home-newslist__article:not(:last-child) {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .home-newslist__article:not(:last-child)::before {
    content: "";
    position: absolute;
    left: calc(50% - 30px);
    bottom: 0;
    display: block;
    height: 1px;
    width: 60px;
    background: #dcdedf;
  }
}
.home-newslist__article-image {
  min-width: 91px;
  margin: 0 20px 0 0;
}
@media (max-width: 991px) {
  .home-newslist__article-image {
    min-width: 82px;
  }
}
.home-newslist__article-headline {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .home-newslist__article-headline {
    margin-bottom: 0;
    font-size: 15px;
  }
}
.home-newslist__article-headline a {
  color: #171715;
  text-decoration: none;
  transition: color 0.2s;
}
.home-newslist__article-headline a:hover {
  color: #df000e;
}
.home-newslist__article-description {
  line-height: 18px;
}

body.page-layout-homepage {
  margin-bottom: 0;
}
body.page-layout-homepage #header {
  min-height: auto;
}
body.page-layout-homepage #header .mod_article {
  margin-bottom: 0;
}
body.page-layout-homepage #container {
  width: 100%;
  min-height: auto;
  padding: 0;
}
body.page-layout-homepage #footer {
  display: none;
}
body.page-layout-homepage .full-screen-section {
  position: relative;
  height: calc(100vh - 85px);
  min-height: 600px;
  background-color: #df000e;
  flex-grow: 1;
}
@media (max-width: 991px) {
  body.page-layout-homepage .full-screen-section {
    height: auto;
    min-height: auto;
  }
}
body.page-layout-homepage .full-screen-section .mod_newslist {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  max-height: 120px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  z-index: 5;
}
@media (max-width: 991px) {
  body.page-layout-homepage .full-screen-section .mod_newslist {
    position: static;
    max-height: none;
  }
}