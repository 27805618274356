body.page-layout-homepage {
    margin-bottom: 0;

    #header {
        min-height: auto;

        .mod_article {
            margin-bottom: 0;
        }
    }

    #container {
        width: 100%;
        min-height: auto;
        padding: 0;
    }

    #footer {
        display: none;
    }

    .full-screen-section {
        position: relative;
        height: calc(100vh - #{$headerHeight});
        min-height: 600px;
        background-color: #df000e;
        flex-grow: 1;

        @media (max-width: 991px) {
            height: auto;
            min-height: auto;
        }

        .mod_newslist {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            max-height: 120px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            z-index: 5;

            @media (max-width: 991px) {
                position: static;
                max-height: none;
            }
        }
    }
}
