div[class*="ce_rsce_intro_"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ce_rsce_intro_support,
.ce_rsce_intro_text {
    background-color: #f9f9f9;

    @media (max-width: 991px) {
        background-color: transparent;
    }
}

.intro-wrapper {
    max-width: 1200px;
    padding: 0 ($gutter * 2);
    margin: 0 auto;

    @media (max-width: 991px) {
        padding: 0 40px;
    }
}

.intro {
    display: flex;
    padding: 0 $gutter;
    min-height: 670px;

    @media (max-width: 991px) {
        flex-wrap: wrap;
        min-height: auto;
    }

    &__image {
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        height: 670px;
        width: 670px;

        @media (max-width: 991px) {
            margin: 0 auto 75px auto;
            height: 375px;
            width: 375px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__map {
        height: 670px;
        width: 670px;
        padding-left: 0 !important;
        padding-right: 0 !important;

        @media (max-width: 991px) {
            margin: 0 auto 75px auto;
            height: 375px;
            width: 375px;
        }
    }

    &__mapcontent {
        height: 100%;
    }

    &__headline {
        margin-bottom: 30px;
    }

    &__content {
        max-width: 345px;
        padding: 94px 0 24px 0;
        margin-left: 90px;

        @media (max-width: 991px) {
            max-width: 100%;
            width: 100%;
            padding: 0;
            margin-left: 0;
        }
    }

    &__lead,
    &__address,
    &__contact,
    &__opening-hours {
        font-size: 18px;
        line-height: 30px;
    }

    &__address {
        margin-bottom: 20px;
    }

    &__contact {
        margin-bottom: 40px;
    }

    &__description {
        margin-top: 30px;
        font-size: 14px;
        line-height: 24px;
    }

    &__small-image {
        margin: 58px 0 0 0;

        @media (max-width: 991px) {
            margin-top: 10px;
        }
    }

    &__support {
        display: flex;
        flex-wrap: wrap;
        margin-top: 45px;

        &-link {
            flex-grow: 1;
            display: block;
            margin: 20px;
            padding-top: 45px;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: 23px auto;
            color: #171715;
            font-size: 16px;
            line-height: 1;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }

            &--phone {
                background-image: url('../../images/icon-phone.svg');
            }

            &--email {
                background-image: url('../../images/icon-email.svg');
            }
        }
    }

    &--image,
    &--contact {
        justify-content: center;

        @media (max-width: 991px) {
            margin-bottom: 54px;
            justify-content: normal;
            padding-left: 0;
            padding-right: 0;
        }

        .intro__content {
            max-width: 670px;
            padding-right: 325px;

            @media (max-width: 991px) {
                max-width: 100%;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    &--support,
    &--text {
        padding: 84px 0 94px 0;
        min-height: auto;

        @media (max-width: 991px) {
            margin-bottom: 54px;
            padding: 30px 0 0 0;
        }

        .intro__headline {
            margin-bottom: 0;
        }

        .intro__content {
            margin-left: 0;
            padding: 30px 0 20px 0;
            max-width: 100%;
        }

        [class*=col-] {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--support {
        .intro__content {
            padding-bottom: 0;
        }
    }
}
