.ce_rsce_slider {
    height: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.slider {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    background: url('../../images/home-background.svg') no-repeat center;
    background-size: cover;
    overflow: hidden;

    @media (max-width: 991px) {
        padding: 60px 30px 46px 30px;
        flex-direction: column;
        background-image: url('../../images/home-background-mobile.svg');
    }

    &__image {
        width: 35vw;
        position: absolute;
        top: 0;
        left: calc(50% - 17.4vw);
        pointer-events: none;
        z-index: -1;

        @media (min-height: 50vw) {
            top: auto;
        }

        @media (max-width: 991px) {
            width: 358px;
            left: calc(50% - 179px);
            top: calc(50% - 254px);
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 134%;
            background: url('../../images/home-chameleon-2022.png') no-repeat center;
            background-size: cover;

            @media (max-width: 991px) {
                background-image: url('../../images/home-chameleon-mobile-2022.png');
            }
        }
    }

    &__box {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 50%;
        color: #fff;

        @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 60px;
        }

        &--2 {
            @media (max-width: 991px) {
                margin-bottom: 0;
            }

            .slider__box-link {
                background-color: #e72835;

                &:hover {
                    background-color: #181716;
                    color: #fff;
                }
            }
        }

        &-subtitle {
            margin-bottom: 46px;
            font-size: 24px;

            @media (max-width: 991px) {
                display: none;
            }
        }

        &-title {
            margin-bottom: 33px;
            font-size: 36px;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.02em;

            @media (max-width: 991px) {
                margin-bottom: 17px;
                font-size: 24px;
            }
        }

        &-description {
            margin-bottom: 34px;
            text-align: center;
            font-size: 16px;
            line-height: 30px;

            @media (max-width: 991px) {
                margin-bottom: 39px;
                font-size: 14px;
                line-height: 20px;
            }
        }

        &-link {
            display: block;
            min-width: 220px;
            text-align: center;
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 20px;
            font-weight: 500;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;
            background-color: #181716;
            transition: background-color .2s;

            @media (max-width: 991px) {
                min-width: 170px;
                height: 30px;
                line-height: 30px;
                font-size: 13px;
            }

            &:hover {
                background-color: #df000e;
                color: #fff;
            }
        }
    }

    &__hint {
        display: none;

        @media (min-width: 992px) {
            display: block;
            position: absolute;
            left: calc(50% - 100px);
            top: 63%;
        }

        &-tooltip {
            position: absolute;
            left: calc(-50% + 20px);
            bottom: calc(100% + 7px);
            pointer-events: none;
            opacity: 0;
            transform: translateX(calc(-50% + 20px));
            transition: opacity .2s;

            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: calc(50% - 9px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 9px 9px 0 9px;
                border-color: #fff transparent transparent transparent;
                z-index: 6;
            }

            &-content {
                min-width: 314px;
                padding: 20px;
                background: #fff;
                border-radius: 5px;
                box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
                text-align: center;
            }

            &-headline {
                margin-bottom: 5px;
                font-size: 14px;
                font-style: italic;
            }

            &-text {
                color: #87888b;
                font-size: 12px;
                line-height: 1.5;
                font-style: italic;
            }
        }

        &:hover .slider__hint-tooltip {
            opacity: 1;
        }

        &-pulsar {
            display: block;
            width: 40px;
            height: 40px;
            cursor: help;

            &-static {
                background: #fff;
                border-radius: 50px
            }

            &-pulse,
            &-static {
                display: inline-block;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%)
            }

            &-pulse {
                border-radius: 50px;
                background: rgba(255,255,255,.4);
                border: 1px solid #fff;
                animation: onboard-hotspot-pulse 2s infinite;
                animation-fill-mode: forwards;
            }
        }
    }

    @keyframes onboard-hotspot-pulse {
        0% {
            width: 10px;
            height: 10px
        }

        to {
            width: 40px;
            height: 40px;
            opacity: 0
        }
    }
}
