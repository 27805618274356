#header {
    min-height: 150px;

    @media (max-width: 991px) {
        margin-bottom: 0;
        min-height: 75px;
    }

    .mod_article {
        margin-bottom: 115px;

        @media (max-width: 991px) {
            margin-bottom: 15px;
        }
    }
}

.header {
    display: flex;
    align-items: stretch;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $gutter;
    position: relative;
    height: $headerHeight;
    font-size: 13px;

    @media (max-width: 991px) {
        height: $mobileHeaderHeight;
        padding: 0 20px;
    }

    &__logo {
        display: flex;
        align-items: center;
        margin-right: auto;
    }

    &__menu {
        display: flex;
        align-items: flex-end;

        &--desktop {
            @media (max-width: 991px) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media (max-width: 991px) {
                display: flex;
                align-items: center;
            }
        }
    }

    &__navigation {
        ul, li {
            @extend %reset;
        }

        li::before {
            display: none;
        }

        ul.level_1 {
            display: flex;

            > li {
                position: relative;
                white-space: nowrap;

                &.submenu {
                    &::after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: calc(50% - 12px);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 12px 11px 12px;
                        border-color: transparent transparent #fff transparent;
                        z-index: 5;
                        opacity: 0;
                        transition: opacity .2s;
                    }

                    &:hover {
                        &::after {
                            opacity: 1;
                        }

                        > div.level_2-wrapper {
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }

                    &.last > div.level_2-wrapper {
                        left: auto;
                        right: 0;

                        &.has-image {
                            justify-content: flex-end;
                        }
                    }
                }

                &:not(:last-child) {
                    margin-right: 18px;
                }

                > a,
                > strong {
                    position: relative;
                    z-index: 5;
                    display: block;
                    padding: 5px 5px 24px 5px;
                    font-weight: 500;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #171715;
                    transition: color .2s;

                    &::before {
                        content: '';
                        display: block;
                        width: 0;
                        height: 2px;
                        position: absolute;
                        left: 50%;
                        bottom: 21px;
                        background: #e72835;
                        transition: all .2s;
                    }
                }

                > a:hover,
                > a.trail,
                > strong {
                    color: #df000e;

                    &::before {
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }

        div.level_2-wrapper {
            position: absolute;
            left: 0;
            top: 100%;
            display: block;
            padding-top: 11px;
            transition: opacity .2s;
            opacity: 0;
            pointer-events: none;
            z-index: 5;

            &.has-image {
                display: flex;
                left: -125px;
                min-width: 525px;

                .header-navigation-image {
                    display: flex;
                    margin: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        ul.level_2 {
            min-width: 200px;
            padding: 35px;
            background-color: #fff;
            box-shadow: 0 0 51px 0 rgba(0, 0, 0, 0.2);
            white-space: normal;

            &.has-image {
                min-height: 225px;
                padding-right: 50px;
            }

            > li {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                > a,
                > strong {
                    font-weight: normal;
                    text-decoration: none;
                    color: #171715;
                    transition: color .2s;
                }

                > a:hover,
                > strong {
                    color: #df000e;
                }

                // Hide all levels below
                ul {
                    display: none;
                }
            }
        }
    }

    &__search {
        margin-left: 19px;
        padding-bottom: 26px;

        &::before {
            content: "";
            position: absolute;
            top: 100%;
            width: 0;
            height: 0;
            margin-left: -2px;
            border-style: solid;
            border-width: 0 12px 11px 12px;
            border-color: transparent transparent #fff transparent;
            z-index: 6;
            opacity: 0;
            transition: opacity .2s;
        }

        body.search-content-active &::before {
            opacity: 1;
        }

        &-trigger-link {
            display: block;
            width: 20px;
            height: 20px;
            background: url('../../images/header-search.svg') no-repeat center center;
            background-size: 16px 16px;
            text-indent: -9999px;
            text-decoration: none;
            cursor: pointer;

            body.search-content-active & {
                opacity: .5;
            }
        }

        &-content-wrapper {
            position: absolute;
            top: $headerHeight + 11px;
            left: $gutter;
            right: $gutter;
            opacity: 0;
            transition: opacity .2s;
            pointer-events: none;
            z-index: 5;

            @media (max-width: 991px) {
                top: $mobileHeaderHeight + 11px;
            }

            body.search-content-active & {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &-text {
            width: 100%;
            padding: 0 25px;
            font-size: 24px;
            font-weight: 300;
            color: #171715;
            border: none;
            border-radius: 0;
            background: #fff;
            box-shadow: 0 0 51px 0 rgba(0, 0, 0, 0.2);
            height: 69px;
            line-height: 69px;
            -webkit-appearance: none;
        }

        &-submit {
            display: none;
        }
    }

    &__user {
        margin-left: 29px;
    }

    &__login {
        position: relative;
        padding-bottom: 19px;

        &-trigger-link {
            display: block;
            height: 30px;
            line-height: 30px;
            padding: 0 18px;
            border-radius: 15px;
            font-weight: 500;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;
            background-color: #87888b;
            transition: background .2s;

            &:hover {
                background-color: #df000e;
                color: #fff;
            }
        }
    }

    &__mobile-menu {
        .mobile_menu_trigger {
            width: 26px;
            height: 19px;
            background: url("../../images/mobile-menu-open.svg") no-repeat;

            &.active {
                margin-top: 1px;
                margin-right: 1px;
                width: 21px;
                height: 21px;
                background-image: url("../../images/mobile-menu-close.svg");
            }
        }
    }
}

.mobile_menu {
    .inner {
        padding: 0 20px 40px 20px;
        background-color: #fff;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: $mobileHeaderHeight;

        .header__search-trigger-link {
            background-size: 20px 20px;
        }

        .header__search {
            padding-bottom: 24px;

            &-text {
                font-size: 18px;
            }
        }

        .header__user {
            margin-left: 24px;

            .header__login-trigger-link {
                font-weight: 700;
            }

            .header__login-content-wrapper {
                width: calc(75vw - 40px);
            }

            .header__login-content {
                padding: 20px;

                table {
                    table,
                    tbody,
                    tr,
                    td {
                        display: block;
                        padding: 0;
                    }

                    tr {
                        margin-bottom: 15px;
                    }

                    td:first-child {
                        margin-bottom: 5px;
                        padding-right: 0;
                        text-align: left;
                    }
                }

                .row_2 .col_0,
                .row_3 .col_0 {
                    display: none;
                }
            }

            .formbody .captcha, .formbody .select_container, .formbody .text, .formbody .textarea {
                width: 100%;
                float: none;
            }

            .formbody .submit_container {
                float: none;
                margin-bottom: 15px;
            }
        }
    }

    .mod_navigation {
        padding: 28px 12px;

        ul, li {
            @extend %reset;
        }

        li::before {
            display: none;
        }

        a,
        strong {
            display: block;
            color: #171715;
            font-size: 18px;
            line-height: 35px;
            text-decoration: none;
            font-weight: normal;
            transition: color .2s;
        }

        a:hover,
        a.trail,
        strong {
            color: #e72835;
        }

        ul.level_1 > li {
            margin-bottom: 20px;

            > a,
            > strong {
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        ul.level_2 {
            padding-left: 20px;
        }

        ul.level_3 {
            padding-left: 20px;
        }
    }
}
