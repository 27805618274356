.formbody,
.hbspt-form {
    .inputs-list {
        &, li {
            margin: 0;
            padding: 0;
            list-style: none;

            &:before {
                content: none;
            }
        }
    }

    label,
    legend {
        span.mandatory,
        span.hs-form-required {
            margin-left: 4px;
        }
    }

    .error {
        > label,
        > legend {
            color: #d80000;
        }
    }

    p.error,
    .hs-error-msgs {
        display: none;
    }

    .widget,
    .hs-form-field {
        margin-bottom: 11px;

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        > label,
        > fieldset > legend {
            width: 225px;
            float: left;
            padding-right: 20px;
            text-align: right;
        }

        > label {
            padding-top: 10px;
        }

        &.hs-fieldtype-radio > label {
            padding-top: 0;
        }

        .location-inputs {
            float: left;
        }

        .location-inputs:after {
            content: "";
            display: table;
            clear: both;
        }

        .location-inputs input:first-child {
            width: 108px;
            float: left;
            margin-right: 15px;
        }

        .location-inputs input:last-child {
            width: 307px;
            float: left;
        }
    }

    .text,
    .textarea,
    .captcha,
    .select_container,
    .hs-fieldtype-text input,
    .hs-fieldtype-phonenumber input,
    .hs-fieldtype-textarea textarea,
    .hs-fieldtype-select > .input {
        width: 430px;
        height: 40px;
        float: left;
        margin: 0;
        padding: 0 14px;
        background-color: #fff;
        border: 1px solid #d1d1d0;
        border-radius: 0;
        font-size: 14px;
        line-height: 40px;
        font-weight: 300;
        color: #171715;
        -webkit-appearance: none;
    }

    .text,
    .textarea,
    .captcha,
    .hs-input {
        &.error {
            border-color: #d80000 !important;
        }

        &:focus {
            border-color: #171715;
        }
    }

    .textarea,
    .hs-fieldtype-textarea textarea {
        height: 141px;
        padding-top: 10px;
        line-height: 18px;
        resize: vertical;
    }

    .select_container,
    .hs-fieldtype-select > .input {
        position: relative;
        padding-right: 38px;
        background-image: url("../images/form-select-dropdown.png");
        background-repeat: no-repeat;
        background-position: 97% center;

        select {
            position: absolute;
            width: 100%;
            inset: 0;
            font-size: inherit;
            font-weight: inherit;
            -webkit-appearance: none;
            background: none;
            border: none;
            cursor: pointer;
            padding: 0 14px;
        }
    }

    .radio_container,
    .checkbox_container,
    .inputs-list {
        margin: 0;
        padding: 0 0 10px;
        border: 0;
    }

    .checkbox_container,
    .hs-fieldtype-checkbox .inputs-list {
        padding-top: 10px;
        padding-bottom: 5px;

        > span,
        .hs-form-checkbox-display {
            position: relative;
            display: block;
            margin-bottom: 5px;
            margin-left: 225px;

            input {
                position: absolute;
                opacity: 0;
            }

            label,
            input + span {
                padding-left: 20px;
                background-image: url("../images/form-checkbox.png");
                background-repeat: no-repeat;
                background-position: left 3px;
                cursor: pointer;
            }

            input:checked + label,
            input:checked + span {
                background-image: url("../images/form-checkbox-active.png");
            }

            input:focus + label,
            input:focus + span {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 6px;
                    width: 11px;
                    height: 11px;
                    border: 1px solid #171715;
                }
            }
        }
    }

    .radio_container,
    .hs-fieldtype-radio .inputs-list {
        li {
            display: inline-block;
        }

        > span,
        .hs-form-radio-display {
            position: relative;
            display: inline-block;
            margin-right: 48px;

            input {
                position: absolute;
                opacity: 0;
            }

            label,
            input + span {
                display: inline-block;
                padding-left: 20px;
                background-image: url("../images/form-radio.png");
                background-repeat: no-repeat;
                background-position: left 4px;
                cursor: pointer;
            }

            input:checked + label,
            input:checked + span {
                background-image: url("../images/form-radio-active.png");
            }

            input:focus + label,
            input:focus + span {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 4px;
                    width: 12px;
                    height: 12px;
                    border: 1px solid #171715;
                    border-radius: 50%;
                }
            }
        }
    }

    .captcha {
        width: 75px;
        margin-right: 8px;
        text-align: center;
    }

    .captcha_text {
        font-weight: bold;
    }

    .submit,
    .hs-button.primary {
        display: inline-block;
        margin-left: 225px;
        min-width: 220px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        border: none;
        border-radius: 20px;
        font-family: "Soho Gothic W01", sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        transition: background-color .2s;
        background-color: #df000e;
        cursor: pointer;
        -webkit-appearance: none;

        &:hover {
            background-color: #181716;
            color: #fff;;
        }
    }

    .explanation,
    .legal-consent-container {
        margin-left: 225px;
        font-size: 11px;
        line-height: 15px;
        color: #bcbcbc;

        p {
            margin-bottom: 0;
        }
    }
}

.contact-form {
    .formbody,
    .hbspt-form {
        padding-top: 36px;
    }

    .widget-textarea,
    .hs-fieldtype-textarea {
        margin-bottom: 4px;
    }

    .widget-explanation,
    .legal-consent-container {
        margin-bottom: 15px;
    }
}

@media (max-width: 991px) {
    .formbody,
    .hbspt-form {
        .text,
        .textarea,
        .captcha,
        .select_container,
        .hs-fieldtype-text input,
        .hs-fieldtype-phonenumber input,
        .hs-fieldtype-textarea textarea,
        .hs-fieldtype-select > .input {
            width: 100%;
        }

        .widget,
        .hs-form-field {
            margin-bottom: 17px;

            > label,
            > fieldset > legend {
                width: auto;
                float: none;
                display: block;
                margin-bottom: 7px;
                padding-top: 0;
                text-align: left;
            }

            .location-inputs {
                position: relative;
                float: none;
                padding-left: 103px;
            }

            .location-inputs input:first-child {
                width: 88px;
                left: 0;
                top: 0;
                position: absolute;
                float: none;
            }

            .location-inputs input:last-child {
                width: 100%;
                float: none;
            }
        }

        .checkbox_container > span,
        .hs-fieldtype-checkbox .inputs-list .hs-form-checkbox-display {
            margin-left: 0;
        }

        .submit,
        .hs-button.primary {
            margin-left: 0;
        }

        .submit_container {
            text-align: center;
        }

        .explanation,
        .legal-consent-container {
            margin-left: 0;
        }
    }
}
