.ce_tinline_anniversary {
    ul {
        display: grid;
        grid-template: 1fr / 1fr;
        grid-gap: 30px;
        margin: 0;
        padding: 0;

        @media screen and (min-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    li {
        position: relative;
        margin: 0;
        padding: 0;
        font-weight: 500;
        aspect-ratio: 1/1;

        // Remove list item
        &:before {
            content: none;
        }
    }

    a {
        display: block;
        color: inherit;
        text-decoration: none;
    }

    figure {
        margin: 0;
    }

    img {
        width: 100%;
    }

    .latest {
        aspect-ratio: unset;
        grid-column: 1/-1;

        .title {
            padding: 30px;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
        }
    }

    .open,
    .latest {
        .number {
            position: absolute;
            top: 0;
            left: 20px;
            z-index: 10;
            width: 44px;
            height: 56px;
            font-size: 24px;
            line-height: 56px;
            text-align: center;
            color: #fff;
            background: #df000e;
        }
    }

    .open {
        figure:before {
            content: '';
            position: absolute;
            inset: 0;
            background: rgb(136,4,11);
            background: linear-gradient(150deg, rgba(136,4,11,1) 26%, rgba(138,0,58,1) 60%);
            mix-blend-mode: multiply;
            z-index: 2;
            transition: opacity .3s ease;
        }

        .title {
            position: absolute;
            inset: 0;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 18px;
            color: #fff;
            text-align: center;
            transition: opacity .3s ease;
        }

        &:hover {
            figure:before,
            .title {
                opacity: 0;
            }
        }
    }

    .closed {
        display: flex;
        justify-content: center;
        align-items: center;

        &.dark {
            background: #c9111e; // dunkel
        }

        &.medium {
            background: #e72835; // mittel
        }

        &.light {
            background: #fd3a47; // hell
        }

        .number {
            font-size: 48px;
            color: #fff;
        }
    }
}

// Image link on homepage
.ce_hyperlink.anniversary {
    position: absolute;
    top: -5px;
    right: calc(100vw / 2 - 500px);
    z-index: 2;

    a {
        display: block;
        width: 240px;
        height: 170px;
        background: url("../../images/anniversary.svg") 0 0 / contain no-repeat;
        text-indent: -999em;
    }

    @media (max-width: 991px) {
        right: -20px;
    }
}
