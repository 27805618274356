.image-text {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: 991px) {
        margin-top: 60px;
        margin-bottom: 60px;
        flex-direction: column;
        justify-content: normal;
    }

    &__headline {
        margin-bottom: 10px;
    }

    &__content {
        max-width: 600px;
        padding: 0 $gutter;

        @media (max-width: 991px) {
            padding: 0 10px;
            order: 1;
        }
    }

    &__main-image {
        margin-bottom: 0;
        max-width: 525px;
        padding: 0 $gutter;

        @media (max-width: 991px) {
            margin-bottom: 34px;
            padding: 0 10px;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 24px;
    }

    &__image {
        margin: 0;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}
