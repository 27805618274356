.product-videos {
    max-width: 880px;
    margin: 0 auto 50px auto;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: 1199px) {
        max-width: 100%;
        margin-bottom: 30px;
        padding-left: $gutter !important;
        padding-right: $gutter !important;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        display: block;
        margin-bottom: 50px;
        font-weight: 500;
        text-decoration: none;
        color: #181716;
        transition: color .2s;

        @media (max-width: 1199px) {
            width: 33.3333%;
        }

        @media (max-width: 767px) {
            width: 50%;
        }

        @media (max-width: 575px) {
            width: 100%;
        }

        &:hover {
            color: #df000e;
        }

        &-title {
            font-weight: 500;
            font-size: 18px;
        }

        &-image {
            position: relative;
            border: 1px solid #d6d7d7;

            &::before {
                content: '';
                position: absolute;
                right: 20px;
                bottom: 20px;
                display: block;
                width: 35px;
                height: 35px;
                background: url('../../images/video-play.svg') no-repeat;
                box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.44);
                border-radius: 50%; // make the shadow round
                pointer-events: none;
            }
        }
    }
}
