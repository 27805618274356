$headerHeight: 85px;
$mobileHeaderHeight: 78px;

/* Grid */
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$columns: 16;
$gutter: 15px;


/* Reset style */
%reset {
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
    border: 0;
}

.reset {
    @extend %reset;
}
