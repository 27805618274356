.product-features {
    max-width: 880px;
    margin: 0 auto 50px auto;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: 991px) {
        max-width: 100%;
        margin-bottom: 30px;
        padding-left: $gutter !important;
        padding-right: $gutter !important;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;

        &--hidden {
            display: none;
        }
    }

    &__item {
        margin-bottom: 50px;

        @media (max-width: 1199px) {
            width: 33.3333%;
        }

        @media (max-width: 767px) {
            width: 50%;
        }

        @media (max-width: 575px) {
            width: 100%;
        }

        &-title {
            font-weight: 500;
            font-size: 18px;
        }
    }

    &__more {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        &-link {
            display: block;
            min-width: 220px;
            text-align: center;
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            border-radius: 20px;
            font-weight: 500;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;
            background-color: #181716;
            transition: background-color .2s;

            &:hover {
                background-color: #df000e;
                color: #fff;
            }
        }
    }

    &__link-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 14px;
    }

    &__link {
        font-weight: 500;
        font-size: 12px;
        text-decoration: none;
        color: #181716;
        transition: color .2s;

        &:hover {
            color: #df000e;
        }
    }
}
