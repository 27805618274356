/* Grid mixin */
@mixin generate-grid($container-width, $prefix:"") {
    $columnWidth: calc($container-width / $columns);

    @for $i from 1 through $columns {
        $width: $columnWidth * $i;

        @if $prefix != "" {
            .col-#{$prefix}-#{$i} {
                width: $width;
            }
            .offset-#{$prefix}-#{$i} { padding-left: $width !important }
        } @else {
            .col-#{$i} { width: $width }
            .offset-#{$i} { padding-left: $width !important }
        }
    }
}

/* Other rules */
.mod_article:after,
.mod_article > .inner:after,
.grid-row:after,
.grid-inner-row:after {
    content: "";
    display: table;
    clear: both;
}

.grid-row {
    margin-left: -1 * $gutter;
    margin-right: -1 * $gutter;
}

.mod_article [class*='mod_'],
.mod_article [class*='ce_'],
.mod_article .hbspt-form {
    padding-left: $gutter;
    padding-right: $gutter;
}

[class*='col-'] {
    float: left;
    width: 100%;
    padding-left: $gutter;
    padding-right: $gutter;
}

.mod_article[class*='col-'] > * {
    padding-left: 0;
    padding-right: 0;
}

/* Generate grids */
#header .inner,
#container,
#footer .inner {
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

@include generate-grid(100%, xs);

@media (min-width: $screen-sm) {
    #header .inner,
    #container,
    #footer .inner {
        width: 100%;
    }

    @include generate-grid(100%, sm);

    .col-sm-8:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width: $screen-md) {
    #header .inner,
    #container,
    #footer .inner {
        width: 980px;
    }

    @include generate-grid(960px);
    @include generate-grid(960px, md);

    .col-sm-8:nth-child(2n+1) {
        clear: none;
    }

    .col-md-5:nth-child(3n+1) {
        clear: left;
    }

    .col-md-1-3 {
        width: 33.3333%;
    }
}

@media (min-width: $screen-lg) {
    #header .inner,
    #container,
    #footer .inner {
        width: 1200px;
    }

    @include generate-grid(1200px);
    @include generate-grid(1200px, lg);

    .col-sm-8:nth-child(2n+1),
    .col-md-5:nth-child(3n+1) {
        clear: none;
    }

    .col-lg-4:nth-child(3n+1) {
        clear: left;
    }
}
