.interest-bar {
    margin: 90px -50vw;
    padding: 75px 0;
    background-color: #e72835;
    color: #fff;

    @media (max-width: 991px) {
        margin: 30px ($gutter * -1); // horizontal margin resets the parents padding
        padding: 40px 0 20px 0;
    }

    &__container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 $gutter;

        @media (max-width: 1199px) {
            max-width: 980px;
        }

        @media (max-width: 991px) {
            max-width: 100%;
        }
    }

    &__row {
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            flex-direction: column;
            align-items: normal;
        }
    }

    &__title {
        font-size: 60px;
        line-height: 1;

        @media (max-width: 991px) {
            margin-bottom: 30px;
            font-size: 50px;
            text-align: center;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        padding-left: 64px;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 39px 39px;
        font-size: 14px;
        line-height: 24px;
        min-height: 40px;

        @media (max-width: 991px) {
            justify-content: center;
            margin-bottom: 30px;
            background-position: center top;
            padding-left: 0;
            padding-top: 50px;
            text-align: center;
        }

        &:not(:last-child) {
            margin-bottom: 22px;

            @media (max-width: 991px) {
                margin-bottom: 42px;
            }
        }

        &--phone {
            background-image: url('../../images/icon-phone-white-circle.svg');
        }

        &--newsletter {
            background-image: url('../../images/icon-newsletter-white-circle.svg');
        }

        p {
            margin: 0;
        }

        a {
            color: #fff;
        }
    }
}
