.link-boxes {
    &__items {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: $screen-lg) {
            padding-left: 75px;
        }

        > * {
            margin-bottom: $gutter * 2;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 30px 25px;
        background-color: #f2f4f6;
        border-bottom: 1px solid #dcdedf;
        border-top: 1px solid #dcdedf;
    }

    &__header {
        margin-bottom: 15px;
        padding-left: 60px;
        min-height: 55px;
        background: url('../../images/icon-link-box.svg') no-repeat top left / auto 50px;
    }

    &__subtitle {
        margin: 5px 0 2px;
        font-size: 14px;
        line-height: 1;
    }

    &__title {
        font-size: 20px;
        font-family: 'Soho Gothic W01', sans-serif;
        font-weight: 500;
        line-height: 1.25;
    }

    &__image {
        margin-bottom: 10px;
        height: 70px;

        img {
            width: auto;
            height: 100%;
        }
    }

    &__text {
        margin-bottom: 36px;
    }

    &__link-wrapper {
        margin-top: auto;
        text-align: center;
    }

    &__link {
        display: inline-block;
        min-width: 220px;
        text-align: center;
        height: 42px;
        line-height: 38px;
        padding: 0 20px;
        border-radius: 20px;
        border: 2px solid #181716;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        color: #181716;
        transition: all .2s;

        &:hover {
            color: #fff;
            background-color: #181716;
        }
    }
}
