/* desktop.css */
*, *:before, *:after {
  box-sizing: inherit
}

*:focus {
  outline: none
}

html {
  min-height: 100%;
  position: relative;
  box-sizing: border-box
}

body {
  margin-bottom: 295px;
  font: 14px/24px "Soho Gothic W01", sans-serif;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: #171715
}

h1, h2, h3, h4, h5, h6, hgroup, ul, ol, dd, p, figure, pre, table, fieldset, hr {
  margin-top: 0;
  margin-bottom: 20px
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 1;
  font-weight: 500;
  font-family: "Soho Gothic W01", sans-serif;
}

h1, h2 {
  position: relative;
  margin-bottom: 25px;
  padding-top: 30px;
  color: #181716;
  font-weight: normal;
  line-height: 1;

  &::before {
    content: '';
    display: block;
    width: 72px;
    height: 2px;
    background: #e72835;
    position: absolute;
    top: 0;
    left: 0;
  }
}

h1.ce_headline::before,
h2.ce_headline::before {
  left: 10px;
}

h1 {
  font-size: 48px;
  line-height: 52px;

  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 44px;
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 40px;

  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 34px;
  }
}

h3 {
  font-size: 18px;
  line-height: 24px;
}

h4, h5, h6 {
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 15px;
  line-height: 30px;
  font-weight: normal;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0
}

table td, table th {
  padding: 4px;
  vertical-align: top
}

blockquote {
  margin-top: 0;
  margin-right: 55px;
  margin-left: 55px;
  text-align: center
}

blockquote p {
  margin-bottom: 14px;
  font-style: italic
}

blockquote cite {
  font-style: normal
}

dl {
  margin: 0 20px 20px;
  padding: 0
}

dl dt, dl dd {
  margin: 0;
  padding: 8px 34px
}

dl ul {
  margin: 0;
  padding: 0
}

dl dt {
  font-weight: 500;
}

dl dd {
  margin-top: -36px;
  padding-left: 205px
}

dl dt.odd, dl dd.odd {
  background-color: #f7f8f9
}

dl dt.even, dl dd.even {
  background-color: #f0f2f4
}

ul {
  padding-left: 16px;
  list-style-type: none
}

p + ul {
  margin-top: -14px
}

ul li {
  position: relative;
  margin: 0 0 4px;
  padding: 0 0 0 13px
}

ul li:before {
  width: 4px;
  height: 4px;
  left: 0;
  top: 8px;
  position: absolute;
  display: block;
  background-color: #171715;
  border-radius: 50%;
  content: ""
}

ul ul {
  margin-top: 5px;
  margin-bottom: 0;
  padding-left: 38px
}

ul ul li:before {
  width: 3px;
  height: 3px;
  top: 9px
}

img {
  vertical-align: middle
}

strong {
  font-weight: 500;
}

a {
  text-decoration: underline;
  color: #df000e
}

a:hover {
  color: #171715
}

input {
  filter: none; // remove FF autofill yellow background @see https://stackoverflow.com/a/55326037/3628692
}

.block {
  overflow: visible
}

.mobile-only {
  display: none
}

#container {
  padding-bottom: 90px;
  min-height: 500px; // make sure the header dropdown navigation is not cut off when there is too less content
}

.mod_article .ce_text:first-child h1 {
  margin-bottom: 20px;
}

.mod_article .ce_text:first-child h1 + p {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 26px
}

.mod_article .ce_text {
  margin-bottom: 60px;
}

.mod_article .ce_text.short-text {
  max-width: 645px + ($gutter * 2);
}

.ce_cfg_tabs_wrapper_start {
  padding-top: 60px
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li {
  margin-right: 10px
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li:before {
  display: none
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li a {
  background: -moz-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: -webkit-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: -o-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: -ms-linear-gradient(top, #e9e9e9, #d5d6d6);
  background: linear-gradient(to bottom, #e9e9e9, #d5d6d6);
  -pie-background: linear-gradient(top, #e9e9e9, #d5d6d6);
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 0px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  color: #171715;
  border-width: 1px 1px 0 1px !important;
  padding: 10px 22px !important
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li.active a, .ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li:hover a {
  background: #ffffff
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_content {
  padding-top: 24px;
  padding-right: 5px;
  padding-left: 5px;
  border-top: 1px solid #e7e7e7;
  border-right: 0px solid #e7e7e7;
  border-bottom: 0px solid #e7e7e7;
  border-left: 0px solid #e7e7e7
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_content_inner {
  height: auto !important
}

.ce_cfg_tabs_wrapper_start .cfg_tabs_content [class*='ce_'] {
  padding-right: 0;
  padding-left: 0
}

.ce_download, .ce_downloads {
  margin-bottom: 20px;
  padding-top: 20px
}

.ce_download img, .ce_downloads img {
  display: none
}

.ce_download a, .ce_downloads a {
  display: inline-block;
  padding-left: 25px;
  background-image: url("../images/download-pdf.png");
  background-position: left top;
  background-repeat: no-repeat
}

.ce_download .size, .ce_downloads .size {
  display: none
}

.ce_gallery ul,
.ce_downloads ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.ce_downloads ul li {
  margin: 0 0 5px;
  padding: 0
}

.ce_gallery ul li:before,
.ce_downloads ul li:before {
  display: none
}

.ce_gallery ul li {
  margin: 0 10px 10px 0;
  padding: 0;
}

.ce_gallery figure {
  margin: 0;
}

.ce_gallery.clients-gallery ul {
  padding-top: 12px
}

.ce_gallery.clients-gallery ul li {
  width: 25%;
  height: 80px;
  text-align: center;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d
}

.ce_gallery.clients-gallery ul li:before {
  display: none
}

.ce_gallery.clients-gallery ul li .image_container {
  top: 50%;
  position: relative;
  transform: translateY(-50%)
}

.ce_tinline_youtube .content.active a {
  display: block
}

.ce_tinline_youtube .content.active .content-inner {
  position: relative
}

.ce_tinline_youtube .content.active .iframe-box {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: block
}

.ce_tinline_youtube .iframe-box {
  display: none
}

.ce_tinline_youtube iframe {
  border: none
}

.ce_sliderStart .slider-control .slider-prev, .ce_sliderStart .slider-control .slider-next {
  display: none
}

.ce_sliderStart .slider-control {
  height: auto;
  text-align: center
}

.ce_sliderStart .slider-control .slider-menu {
  width: auto;
  position: static;
  display: inline-block;
  margin-left: 0
}

.ce_sliderStart .slider-control .slider-menu b {
  width: 10px;
  height: 10px;
  float: left;
  margin-right: 12px;
  margin-left: 12px;
  background-color: #c4cad6;
  border-radius: 50%;
  text-indent: -9999px
}

.ce_sliderStart .slider-control .slider-menu b.active {
  background-color: #df000e
}

.ce_sliderStart.testimonial-slider {
  margin-bottom: 37px;
  padding-top: 22px
}

.ce_sliderStart.testimonial-slider .slider-control {
  padding-top: 8px
}

.ce_sliderStart.testimonial-slider .ce_text {
  text-align: center
}

.ce_sliderStart.testimonial-slider .ce_text p {
  margin-bottom: 0
}

.arrow-link {
  padding-left: 29px;
  background-image: url("../images/arrow-link.png");
  background-repeat: no-repeat;
  background-position: 13px center
}

.arrow-link:hover {
  background-image: url("../images/arrow-link-hover.png")
}

.ce_tinline_person,
.article-team .ce_text {
  margin-bottom: 40px;
  text-align: center
}

.ce_tinline_person .person-image,
.article-team .ce_text .image_container {
  margin-bottom: 16px
}

.ce_tinline_person .person-image-hover {
  display: none;
}

.ce_tinline_person .person-image:hover {
  .person-image-normal {
    display: none;
  }

  .person-image-hover {
    display: block;
  }
}

.featured-link {
  margin-bottom: 48px;
  text-align: center
}

.featured-link a {
  display: inline-block;
  padding: 5px 33px;
  background-color: #df000e;
  text-decoration: none;
  color: #fff
}

.featured-link a:before {
  margin-right: 3px;
  content: ">"
}

.featured-link a:hover {
  background-color: #171715
}

.support-box {
  padding-top: 11px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center
}

.support-box .inner {
  display: inline-block
}

.support-box .inner:after {
  content: "";
  display: table;
  clear: both
}

.support-box .inner > .phone, .support-box .inner > .email {
  float: left;
  padding-left: 31px;
  text-align: left;
  background-position: left center;
  background-repeat: no-repeat;
  font-size: 16px;
  line-height: 24px
}

.support-box .inner > .phone {
  margin-right: 90px;
  padding-left: 30px;
  background-image: url("../images/header-phone.png")
}

.support-box .inner > .email {
  padding-left: 35px;
  background-image: url("../images/header-email.png")
}

.support-box .inner > .phone a, .support-box .inner > .email a {
  text-decoration: none;
  color: #171715
}

.tl_message .tl_confirm {
  font-weight: 500;
  color: #00AE00
}

#left .inside {
  padding-top: 84px;
  padding-right: 85px;
  padding-left: 10px
}

#left .inside > * {
  margin-bottom: 72px
}

#left .inside > *:last-child {
  margin-bottom: 0
}

#left .left-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

#left .left-menu ul li {
  margin: 0 0 17px;
  padding: 0
}

#left .left-menu ul li:last-child {
  margin-bottom: 0
}

#left .left-menu ul li:before {
  display: none
}

#left .left-menu a, #left .left-menu strong {
  display: block;
  padding-bottom: 2px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
  text-decoration: none;
  color: #171715;
  text-transform: uppercase
}

#left .left-menu a:hover, #left .left-menu a.trail, #left .left-menu strong {
  border-color: #171715
}

#left .left-menu a.trail, #left .left-menu strong {
  font-weight: 500;
}

#left .ce_hyperlink {
  margin-bottom: 20px;
  padding-right: 0;
  padding-left: 0
}

#left .ce_hyperlink a {
  display: block;
  padding: 5px 10px;
  text-align: center;
  background-color: #87888b;
  text-decoration: none;
  color: #fff
}

#left .ce_hyperlink a:last-child {
  margin-bottom: 0
}

#left .ce_hyperlink.featured a {
  background-color: #df000e
}

#left .ce_hyperlink a:before {
  margin-right: 3px;
  content: ">"
}

#left .ce_hyperlink a:hover {
  background-color: #171715
}

#footer {
  width: 100%;
  height: 295px;
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  background-color: #171715;
  font-size: 14px;
  line-height: 24px;
  color: #f3f3f3
}

#footer a {
  text-decoration: none;
  color: #f3f3f3
}

#footer a:hover {
  text-decoration: underline
}

#footer .inner {
  padding-right: $gutter;
  padding-left: $gutter;
}

#footer .top-box {
  height: 253px;
  overflow: hidden;
  padding-top: 44px
}

#footer .top-box .inner:after {
  content: "";
  display: table;
  clear: both
}

#footer .footer-menu {
  width: 880px;
  position: relative;
  float: left
}

#footer .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

#footer .footer-menu ul li {
  margin: 0;
  padding: 0
}

#footer .footer-menu ul li:before {
  display: none
}

#footer .footer-menu a.trail, #footer .footer-menu strong.active {
  text-decoration: underline
}

#footer .footer-menu .level_1 > li {
  margin-bottom: 10px;
  width: 25%;
}

#footer .footer-menu .level_1 > li:first-child {
  float: left;
  width: 50%;
}

#footer .footer-menu .level_1 > li:first-child > .level_2 {
  display: flex;
  flex-wrap: wrap;
}

#footer .footer-menu .level_1 > li:first-child > .level_2 > li {
  width: 35%;
}

#footer .footer-menu .level_1 > li:nth-child(2),
#footer .footer-menu .level_1 > li:nth-child(3),
#footer .footer-menu .level_1 > li:nth-child(4) {
  margin-left: 50%;
  margin-bottom: 20px;
}

#footer .footer-menu .level_1 > li:last-child {
  position: absolute;
  top: 0;
  left: 75%;
}

#footer .footer-menu .level_1 > li > a,
#footer .footer-menu .level_1 > li > strong {
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase
}

#footer .footer-menu .level_2 > li > strong {
  font-weight: inherit;
}

#footer .top-box .partners {
    display: flex;
    width: 261px;
    float: right;
    margin-top: 6px;
    margin-right: 18px;

    &:after {
        content: "";
        display: table;
        clear: both
    }

    .partner-col {
        float: left;

        &:first-child {
            margin-right: 15px
        }

        &:last-child {
            text-align: center
        }

        > * {
            display: block;
            margin-bottom: 20px
        }

        > *:last-child {
            margin-bottom: 0
        }

        > span {
            margin-bottom: 5px;
            color: #8b8a8a;
        }
    }
}

#footer .bottom-box {
  height: 42px;
  overflow: hidden;
  padding-top: 11px;
  border-top: 1px solid #454544;
  font-size: 12px;
  line-height: 20px;
  color: #8b8a8a
}

#footer .bottom-box a {
  text-decoration: underline;
  color: #8b8a8a
}

#footer .bottom-box .inner {
  text-align: center
}

#footer .bottom-box .content {
  display: inline-block
}

#footer .bottom-box .content:after {
  content: "";
  display: table;
  clear: both
}

#footer .bottom-box .copyright, #footer .bottom-box .links {
  float: left
}

#footer .bottom-box .copyright {
  margin-right: 48px
}

.pagination {
  padding-top: 6px;
  text-align: center
}

.pagination p {
  display: none
}

.pagination ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none
}

.pagination ul li {
  float: left;
  margin: 0 5px;
  padding: 0
}

.pagination ul li:before {
  display: none
}

.pagination ul li.first, .pagination ul li.last {
  display: none
}

.pagination a, .pagination span {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  color: #171715
}

.pagination a:hover {
  color: #df000e
}

.pagination span {
  color: #bcbcbc
}

.cookiebar {
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: rgba(198, 2, 0, .9);
  font-size: 13px;
  font-weight: normal;
}

.cookiebar__text {
  margin-right: 60px
}

.cookiebar__message {
  margin-right: 0
}

.cookiebar__link {
  white-space: nowrap;
  transition: all .2s
}

.cookiebar__link:hover {
  text-decoration: underline
}

.cookiebar__button {
  height: 27px;
  padding: 0 30px;
  background-color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: normal;
  color: #bc0a0a;
  transition: all .2s
}

.cookiebar__button:hover {
  background-color: #171715;
  color: #fff
}

.article-home-news {
  margin-bottom: 36px
}

.article-home-news:after {
  content: "";
  display: table;
  clear: both
}

.article-home-news .ce_headline > * {
  position: relative;
  margin-bottom: 14px;
  margin-left: 10px;
  padding-top: 0;
  text-align: center;
  font-size: 18px
}

.article-home-news .ce_headline > *:before {
  width: 100%;
  height: 1px;
  top: 50%;
  position: absolute;
  display: block;
  background-color: #e7e7e7;
  content: ""
}

.article-home-news .ce_headline span {
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #fff;
  z-index: 1
}

.mod_article.article-home-news .home-newslist {
  width: 910px;
  float: left;
  padding-left: 0
}

.article-home-news .home-newslist:after {
  content: "";
  display: table;
  clear: both
}

.article-home-news .home-newsticker {
  width: 280px;
  min-height: 223px;
  float: left;
  background-image: url("../images/home-news-ticker.png");
  background-repeat: no-repeat;
  padding: 10px 15px !important;
  background-size: cover
}

.article-home-news .home-newsticker h3 {
  margin-bottom: 16px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #df000e
}

.article-home-news .home-newsticker .more-container {
  display: flex;
  justify-content: center
}

.article-home-news .home-newsticker .more {
  margin-bottom: 0;
  margin-left: -24px
}

.article-home-news .layout_simple {
  margin-bottom: 10px
}

.article-home-news .layout_simple a {
  display: inline-block;
  padding-left: 12px;
  background-image: url("../images/home-news-ticker-arrow.png");
  background-repeat: no-repeat;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  text-decoration: none;
  color: #171715;
  background-position: left 5px
}

.article-home-news .layout_simple a:hover {
  text-decoration: underline
}

.article-home-news .layout_latest h2 {
  margin-top: 16px;
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}

.article-home-news .layout_latest h2 a {
  text-decoration: none;
  color: #171715
}

.article-home-news .layout_latest h2 a:hover {
  text-decoration: underline
}

.article-home-news .layout_latest .image_container {
  margin-bottom: 15px
}

.article-home-news .layout_latest .more {
  margin-bottom: 0
}

.article-home-featured {
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px
}

.article-home-featured .inner {
  height: 423px;
  padding-top: 63px;
  padding-right: 65px;
  padding-left: 65px;
  background-image: url("../images/home-featured-article-bg.jpg");
  background-repeat: no-repeat
}

.article-home-featured .ce_text:first-child h1 {
  margin-bottom: 7px;
  font-weight: 500;
}

.article-home-featured .ce_text p {
  margin-left: 3px
}

.article-home-featured .ce_text p:last-child {
  margin-left: 0;
  padding-top: 15px
}

.article-home-featured .ce_text p:last-child a {
  display: inline-block;
  padding: 5px 33px;
  background-color: #df000e;
  text-decoration: none;
  color: #fff
}

.article-home-featured .ce_text p:last-child a:before {
  margin-right: 3px;
  content: ">"
}

.article-home-featured .ce_text p:last-child a:hover {
  background-color: #171715
}

.article-home-featured .ce_image .image_container, .article-home-featured .ce_tinline_youtube .content {
  padding-top: 52px;
  padding-left: 10px
}

.page-layout-homepage #header .mod_article .ce_sliderStart {
  margin-bottom: 35px
}

.page-layout-product #header .mod_article .inner:after {
  content: "";
  display: table;
  clear: both
}

.page-layout-product #header .mod_article .ce_image {
  float: left
}

.page-layout-product #header .mod_article .ce_text {
  margin-top: 82px;
  margin-left: 381px;
  font-size: 18px;
  line-height: 24px;
  color: #fff
}

.page-layout-product #header .mod_article .ce_text h1 {
  margin-bottom: 12px;
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  color: #fff
}

.page-layout-product #header .mod_article .ce_text p {
  margin-bottom: 27px;
  margin-left: 3px
}

.page-layout-product #header .mod_article .ce_tinline_youtube {
  margin-left: 381px
}

.page-layout-product #container {
  padding-top: 34px
}

body.page-layout-product #main {
  padding-right: 150px
}

body.page-layout-product.page-fullwidth #main {
  padding-right: 0
}

.ce_tinline_courses {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.ce_tinline_courses .filters {
  margin-top: -60px;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between
}

.ce_tinline_courses .filters a {
  position: relative;
  display: block;
  margin-bottom: 37px;
  padding-right: 10px;
  padding-bottom: 11px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #171715
}

.ce_tinline_courses .filters a.active:before, .ce_tinline_courses .filters a:hover:before {
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: #171715;
  content: ""
}

.ce_tinline_courses .status-legend {
  margin-bottom: 11px
}

.ce_tinline_courses .status-legend:after {
  content: "";
  display: table;
  clear: both
}

.ce_tinline_courses .status-legend ul {
  float: right;
  margin: 0;
  padding: 0;
  list-style-type: none
}

.ce_tinline_courses .status-legend ul li {
  position: relative;
  float: left;
  margin: 0 31px 0 0;
  padding: 0 0 0 16px;
  font-size: 12px;
  line-height: 1;
  color: #8b8b8a
}

.ce_tinline_courses .status-legend ul li:last-child {
  margin-right: 0
}

.ce_tinline_courses .status-legend ul li:before {
  width: 9px;
  height: 9px;
  left: 0;
  top: 1px;
  position: absolute;
  display: block;
  border-radius: 50%;
  content: ""
}

.ce_tinline_courses .status-legend ul li.available:before, .ce_tinline_courses .course.status_available .title strong:before {
  background-color: #04e328
}

.ce_tinline_courses .status-legend ul li.waiting:before, .ce_tinline_courses .course.status_waiting .title strong:before {
  background-color: #ffa800
}

.ce_tinline_courses .status-legend ul li.closed:before, .ce_tinline_courses .course.status_closed .title strong:before {
  background-color: #f00
}

.ce_tinline_courses .courses {
  margin-bottom: 30px
}

.ce_tinline_courses .course {
  margin-bottom: 10px;
  padding-top: 16px;
  padding-bottom: 31px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7
}

.ce_tinline_courses .course:last-child {
  margin-bottom: 0
}

.ce_tinline_courses .course--hidden {
  display: none;
}

.ce_tinline_courses .courses-more {
  margin-top: 30px;
  text-align: center;
}

.ce_tinline_courses .courses-more-link {
  display: inline-block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #181716;
  transition: background-color .2s;

  &:hover {
    color: #fff;
    background-color: #df000e;
  }
}

.ce_tinline_courses .course .info-box {
  margin-bottom: 15px
}

.ce_tinline_courses .course .info-box-mobile {
  display: none
}

.ce_tinline_courses .course .title strong {
  position: relative;
  display: block;
  margin-bottom: 1px;
  padding-left: 34px;
  font-size: 15px;
  font-weight: 500;
}

.ce_tinline_courses .course .title strong:before {
  width: 9px;
  height: 9px;
  left: 12px;
  top: 6px;
  position: absolute;
  display: block;
  border-radius: 50%;
  content: ""
}

.ce_tinline_courses .course .title span {
  padding-left: 34px;
  font-size: 12px;
  line-height: 18px;
  color: #8b8b8a
}

.ce_tinline_courses .course .description {
  margin-bottom: 10px;
  margin-left: 34px
}

.ce_tinline_courses .course .link {
  margin-left: 34px
}

.ce_tinline_courses .pagination {
  margin-bottom: 30px
}

.ce_tinline_courses .individual-course {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 33px 28px;
  background-color: #f0f2f4;
  border-top: 1px solid #dadcdd;
  border-bottom: 1px solid #dadcdd;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.ce_tinline_courses .individual-course__headline {
  width: 50%;
  padding-left: 43px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 300;

  @media (max-width: 991px) {
    margin-bottom: 10px;
    width: 100%;
    padding-left: 0;
    font-size: 25px;
    text-align: center;
  }
}

.ce_tinline_courses .individual-course__content {
  width: 50%;
  padding-left: 15px;

  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.ce_tinline_courses .individual-course__content p {
  margin-bottom: 20px
}

.ce_tinline_courses .individual-course__link {
  display: inline-block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #df000e;
  transition: background-color .2s;

  &:hover {
    color: #fff;
    background-color: #181716;
  }
}

.ce_tinline_courses .individual-course p:last-child a:before {
  margin-right: 3px;
  content: ">"
}

.ce_tinline_courses .individual-course p:last-child a:hover {
  background-color: #171715
}

.mod_tinline_course_reader .header h1 + p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 26px
}

.mod_tinline_course_reader .container:after {
  content: "";
  display: table;
  clear: both
}

.mod_tinline_course_reader .details-column > div {
  margin-bottom: 36px;
  padding: 21px 23px 26px;
  background-color: #f0f2f4
}

.mod_tinline_course_reader .details-column h3 {
  margin-bottom: 12px;
  padding-top: 0;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

.mod_tinline_course_reader .details-column .overview-box .details .row {
  margin-bottom: 15px
}

.mod_tinline_course_reader .details-column .overview-box .details .row:last-child {
  margin-bottom: 0
}

.mod_tinline_course_reader .details-column .overview-box .details .row:after {
  content: "";
  display: table;
  clear: both
}

.mod_tinline_course_reader .details-column .overview-box .details .row-label {
  width: 40%;
  float: left;
  padding-right: 10px
}

.mod_tinline_course_reader .details-column .overview-box .details .row-value {
  width: 60%;
  float: left;
  padding-left: 10px;
  font-weight: 500;
}

.mod_tinline_course_reader .details-column .overview-box .details .row-value span {
  font-weight: 300;
}

.mod_tinline_course_reader .details-column .subscribe-link {
  padding-top: 25px;
  text-align: center
}

.mod_tinline_course_reader .details-column .subscribe-link a {
  display: block;
  min-width: 220px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #df000e;
  transition: background-color .2s;

  &:hover {
    color: #fff;
    background-color: #181716;
  }
}

.mod_tinline_course_reader .details-column .subscribe-link a:hover {
  background-color: #171715
}

.mod_tinline_course_reader .details-column .address-box .address {
  margin-bottom: 28px
}

.mod_tinline_course_reader .content-column .description {
  margin-bottom: 60px;
}

.mod_tinline_course_reader .content-column .description p {
  margin-bottom: 40px;
}

.mod_tinline_course_reader .content-column .subscription-form h2 {
  margin-bottom: 29px
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .widget > label, .mod_tinline_course_reader .content-column .subscription-form .formbody .widget > fieldset > legend {
  width: 146px
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .widget-textarea {
  margin-bottom: 3px
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .explanation {
  margin-left: 146px
}

.mod_tinline_course_reader .content-column .subscription-form .formbody .submit {
  margin-left: 358px
}

body.page-layout-course #container {
  width: 1200px
}

.page-layout-content #container {
  width: 900px
}

.newslist-full .layout_latest {
  margin-bottom: 10px;
  padding: 16px 34px 31px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7
}

.newslist-full .layout_latest h2 {
  margin-top: 0;
  margin-bottom: 1px;
  font-size: 15px;
  line-height: 20px;

  &::before {
    display: none;
  }
}

.newslist-full .layout_latest h2 a {
  text-decoration: none;
  color: #171715
}

.newslist-full .layout_latest h2 a:hover {
  text-decoration: underline
}

.newslist-full .layout_latest .info {
  margin-bottom: 15px;
  font-size: 13px;
  color: #8b8b8a
}

.newslist-full .layout_latest .info .divider {
  margin-right: 5px;
  margin-left: 5px
}

.newslist-full .layout_latest .teaser p {
  margin-bottom: 20px
}

.newslist-full .layout_latest .teaser p:last-child {
  margin-bottom: 0
}

.newslist-full .layout_latest .more {
  margin-top: 20px;
  margin-bottom: 0
}

.mod_newsreader .layout_full h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #171715;

  &::before {
    display: none;
  }
}

.mod_newsreader .layout_full .info {
  font-size: 13px;
  color: #8b8b8a
}

.news-navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex
}

.news-navigation ul li {
  margin: 0;
  padding: 0
}

.news-navigation ul li:not(:last-child) {
  margin-right: 38px
}

.news-navigation ul li:before {
  display: none
}

.news-navigation a:not(.invisible), .news-navigation strong {
  position: relative;
  display: block;
  padding-right: 10px;
  padding-bottom: 11px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: #171715
}

.news-navigation a:hover:before, .news-navigation a.trail:before, .news-navigation strong:before {
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: #171715;
  content: ""
}

body.page-layout-contact #header .mod_article {
  padding-top: 0;
  background: none
}

body.page-layout-contact #header .intro__map {
  position: relative
}

body.page-layout-contact #header .intro__map:after {
  width: 1180px;
  height: 130px;
  bottom: -78px;
  left: 10px;
  position: absolute;
  display: block;
  background-color: #fff;
  content: "";
  -ms-transform: skewY(-5deg); /* IE 9 */
  -webkit-transform: skewY(-5deg); /* Safari */
  transform: skewY(-5deg); /* Standard syntax */
}

body.page-layout-contact #header .intro__map .gm-style > div:last-child {
  top: 20px !important;
  right: 0 !important;
  left: auto !important;
  bottom: auto !important
}

body.page-layout-contact #header .intro__map .gm-style > .gmnoprint, body.page-layout-contact #header .intro__map .gm-style > .gmnoscreen, body.page-layout-contact #header .intro__map .gm-style > .gm-style-cc {
  top: 0 !important;
  bottom: auto !important
}

body.page-layout-contact #header .ce_text {
  position: relative;
  margin-top: -8px;
  font-size: 18px;
  line-height: 26px;
  z-index: 1
}

body.page-layout-contact #header .ce_text p {
  margin-bottom: 3px
}

body.page-layout-contact #header .ce_text p:last-child {
  margin-bottom: 0
}

body.page-layout-search #header {
  margin-bottom: 0
}

body.page-layout-search .search-menu {
  padding-right: 0;
  padding-left: 0
}

body.page-layout-search .search-menu ul {
  height: 31px;
  position: relative;
  margin: 0 0 11px;
  padding: 0;
  list-style-type: none
}

body.page-layout-content .search-menu ul:after {
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  position: absolute;
  display: block;
  background-color: #e7e7e7;
  content: ""
}

body.page-layout-search .search-menu ul li {
  height: 100%;
  float: left;
  margin: 0 42px 0 0;
  padding: 0
}

body.page-layout-search .search-menu ul li:before {
  display: none
}

body.page-layout-search .search-menu a, body.page-layout-search .search-menu span {
  height: 100%;
  position: relative;
  display: block;
  text-decoration: none;
  color: #171715;
  z-index: 1
}

body.page-layout-search .search-menu a:hover, body.page-layout-search .search-menu a.trail, body.page-layout-search .search-menu span {
  border-bottom: 2px solid #171715
}

body.page-layout-search .search-menu a.invisible {
  position: absolute !important
}

body.page-layout-search .mod_search > * {
  width: 900px;
  margin-right: auto;
  margin-left: auto
}

body.page-layout-search .mod_search .formbody {
  width: 900px;
  margin: 30px auto;
}

body.page-layout-search .mod_search .formbody .widget-text {
  display: flex;
}

body.page-layout-search .mod_search .formbody .widget-text label {
  padding-top: 0;
}

body.page-layout-search .mod_search .formbody .text {
  width: 100%;
  float: none;
  padding-right: 8px;
  padding-left: 8px;
  border-right: 0
}

body.page-layout-search .mod_search .formbody .text:focus + .submit {
  border-color: #171715
}

body.page-layout-search .mod_search .formbody .widget-submit {
  margin-bottom: 0;
}

body.page-layout-search .mod_search .formbody .submit {
  margin-left: 0;
  min-width: auto;
  width: 50px;
  height: 40px;
  right: 0;
  top: 0;
  background: #fff url("../images/header-search.svg") center center no-repeat;
  background-size: 20px auto;
  border-top: 1px solid #d1d1d0;
  border-right: 1px solid #d1d1d0;
  border-bottom: 1px solid #d1d1d0;
  border-left: 0 solid #d1d1d0;
  text-indent: -9999px;
  border-radius: 0;
}

body.page-layout-search .mod_search .search-header {
  margin-bottom: 15px;
  font-size: 12px;
  color: #bcbcbc
}

body.page-layout-search .mod_search > div {
  margin-bottom: 29px
}

body.page-layout-search .mod_search > div.last {
  margin-bottom: 36px
}

body.page-layout-search .mod_search h3 {
  margin-bottom: 1px;
  font-weight: 500;
}

body.page-layout-search .mod_search h3 a {
  text-decoration: none;
  color: #171715
}

body.page-layout-search .mod_search h3 a:hover {
  text-decoration: underline
}

body.page-layout-search .mod_search h3 .relevance, body.page-layout-search .mod_search .filesize {
  display: none
}

body.page-layout-search .mod_search p {
  margin-bottom: 0
}

body.page-layout-search .mod_search .context {
  margin-bottom: 5px
}

body.page-layout-search .mod_search .url {
  color: #df000e
}

body.page-layout-search .mod_search .pagination {
  margin-bottom: 0;
  padding-top: 16px;
  border-top: 1px solid #e7e7e7
}

.mod_sitemap:after {
  content: "";
  display: table;
  clear: both
}

.mod_sitemap > div {
  width: 280px;
  float: left;
  margin-right: 20px;
  line-height: 25px
}

.mod_sitemap > div.group-3 {
  margin-right: 0
}

.mod_sitemap ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.mod_sitemap ul li {
  margin: 0;
  padding: 0
}

.mod_sitemap ul li:before {
  display: none
}

.mod_sitemap a {
  text-decoration: none;
  color: #171715
}

.mod_sitemap a:hover {
  text-decoration: underline
}

.mod_sitemap .level_1 > li {
  margin-bottom: 70px
}

.mod_sitemap .level_1 > li > a, .mod_sitemap .level_1 > li > span {
  font-size: 18px;
  font-weight: 500;
}

.mod_sitemap .level_2 {
  margin-top: 10px
}

.mod_sitemap .level_3 {
  margin-top: 5px;
  margin-bottom: 25px
}

.mod_sitemap .level_4 {
  display: none
}

.mod_sitemap .group-1 .level_2 {
  margin-top: 15px
}

.mod_sitemap .group-1 .level_2 > li > a, .mod_sitemap .group-1 .level_2 > li > span {
  font-size: 15px;
  font-weight: normal;
}

.mod_tinline_downloads .filter-container {
  margin-bottom: 20px
}

.mod_tinline_downloads .filter-container form {
  text-align: center
}

.mod_tinline_downloads .filter-container .formbody {
  display: inline-block;
  text-align: left
}

.mod_tinline_downloads .filter-container label {
  width: 150px
}

.mod_tinline_downloads .downloads-group {
  margin-bottom: 10px
}

.mod_tinline_downloads .downloads-group .group-header {
  padding-left: 10px;
  background-image: url("../images/mobile/tabs-arrow-down.png");
  background-repeat: no-repeat;
  border-bottom: 1px solid #e7e7e7;
  background-position: 98% center;
  background-size: 12px 8px
}

.mod_tinline_downloads .downloads-group .group-header.active {
  background-image: url("../images/mobile/tabs-arrow-up.png");
  border-color: #df000e
}

.mod_tinline_downloads .downloads-group .group-link {
  padding-top: 0;
  cursor: pointer
}

.mod_tinline_downloads .downloads-group .downloads-items {
  padding: 20px
}

.mod_tinline_downloads .download-item {
  margin-bottom: 10px
}

.mod_tinline_downloads .download-item:last-child {
  margin-bottom: 0
}

.mod_tinline_downloads .download-item .details {
  padding: 10px
}

.mod_tinline_downloads .download-item .details p:last-child {
  margin-bottom: 0
}

.mod_tinline_downloads .download-header {
  border-bottom: 2px solid #e7e7e7
}

.mod_tinline_downloads .download-header:after {
  content: "";
  display: table;
  clear: both
}

.mod_tinline_downloads .download-header .title {
  float: left
}

.mod_tinline_downloads .download-header .actions {
  float: right
}

.mod_tinline_downloads .download-header .actions:after {
  content: "";
  display: table;
  clear: both
}

.mod_tinline_downloads .download-header .actions a {
  float: left;
  display: block;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #e7e7e7;
  text-decoration: none
}

.mod_tinline_downloads .download-header .actions a:first-child {
  margin-left: 0
}

@media (max-width: 1199px) {
  /* desktop-small.css */
  .cookiebar--active {
    flex-direction: column
  }
  .cookiebar__text {
    margin-right: 0;
    margin-bottom: 1em
  }
  #header .top-bar .panels li.panel-login .content .inner {
    padding-right: 0;
    padding-left: 0
  }
  #header .main-menu .level_1 > li {
    width: 115px
  }
  #header .main-menu .level_1 > li:first-child {
    width: 170px
  }
  #header .middle-box .inner:before {
    width: 955px
  }
  #header .ce_tinline_slide .content {
    width: 300px;
    padding-bottom: 24px;
    padding-left: 24px
  }
  #header .ce_tinline_slide .headline {
    font-size: 32px;
    line-height: 36px
  }
  #header .ce_tinline_slide .subheadline {
    margin-bottom: 12px
  }
  #left .inside {
    padding-right: 42px
  }
  #left {
    width: 257px;
    right: 257px
  }
  .page-layout-product #container {
    padding-left: 257px
  }
  .article-home-news .ce_headline > * {
    margin-right: 40px;
    margin-left: 40px
  }
  .mod_article.article-home-news .mod_newslist {
    padding-right: 0;
    padding-left: 40px
  }
  .mod_article.article-home-news .home-newslist {
    width: 650px
  }
  .mod_article.article-home-news .home-newslist .layout_latest.last {
    display: none
  }
  .article-home-featured .inner {
    padding-right: 30px;
    padding-left: 30px
  }
  .page-layout-product #header .mod_article .inner {
    padding-left: 34px
  }
  body.page-layout-product #main {
    padding-right: 0
  }
  body.page-layout-search #header:after {
    width: 960px;
    margin-left: -480px;
    background-size: contain
  }
  body.page-layout-course #container {
    width: 980px
  }
  .mod_tinline_course_reader .content-column {
    width: 655px
  }
  #footer .footer-menu {
    width: 640px
  }
  #footer .footer-menu .level_1 > li {
    width: calc(100% / 4);
  }
}

@media (max-width: 991px) {
  /* mobile-medium.css */
  body {
    margin-bottom: 0
  }
  blockquote {
    margin-right: 0;
    margin-left: 0
  }
  dl {
    margin-right: 0;
    margin-left: 0
  }
  dl dt, dl dd {
    margin-top: 0;
    padding-right: 22px;
    padding-left: 22px
  }
  dl dt {
    padding-top: 6px;
    padding-bottom: 5px
  }
  dl dd {
    padding-top: 0
  }
  dl ul {
    margin-left: 33px
  }

  #header {
    margin-bottom: 6px
  }
  #header .top-bar .panels li .trigger-container:before {
    width: 2px
  }
  #header .top-bar .panels li.panel-login .content .inner {
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px
  }
  #header .top-bar .panels li.panel-login .content .login-box, #header .top-bar .panels li.panel-login .content .registration-box {
    width: 100%;
    float: none;
    padding-right: 0;
    padding-left: 0
  }
  #header .top-bar .panels li.panel-login .content .submit_container {
    text-align: left
  }
  #header .middle-box .inner {
    overflow: hidden;
    padding-bottom: 10px
  }
  #header .middle-box .inner:before {
    width: 100%;
    height: 3px
  }
  #header .main-menu {
    display: none
  }
  #header .logo {
    display: none
  }
  #header .logo-mobile {
    width: 120px;
    float: left;
    display: block;
    margin-top: 30px
  }
  #header .ce_tinline_slide .content {
    width: 240px;
    padding-bottom: 10px;
    padding-left: 10px
  }
  #header .ce_tinline_slide .headline {
    font-size: 22px;
    line-height: 26px
  }
  #header .ce_tinline_slide .subheadline {
    font-size: 14px;
    line-height: 32px
  }
  #header .ce_tinline_slide .link a {
    font-size: 13px
  }
  #header .mod_article .slider-control {
    bottom: 5px
  }
  .arrow-link {
    background-image: url("../images/mobile/arrow-link.png");
    background-size: 9px 6px
  }
  .arrow-link:hover {
    background-image: url("../images/mobile/arrow-link-hover.png")
  }
  .article-home-featured .inner {
    height: auto;
    padding: 30px 10px 12px;
    background: #f8f8f8
  }
  .article-home-news .ce_headline > * {
    margin-right: 0;
    margin-left: 0
  }
  .mod_article.article-home-news .mod_newslist {
    padding-right: 0;
    padding-left: 0
  }
  .mod_article.article-home-featured .ce_text:first-child {
    text-align: left
  }
  .article-home-featured .ce_text p:last-child {
    margin-bottom: 32px;
    padding-top: 10px;
    text-align: center
  }
  .mod_sitemap .level_1 > li {
    margin-bottom: 20px
  }
  .ce_gallery.clients-gallery ul li {
    width: 50%
  }
  .ce_tinline_courses {
    margin-top: 0
  }
  .ce_tinline_courses .filters {
    padding-right: 0;
    padding-left: 0;
    justify-content: space-around;
    flex-wrap: wrap
  }
  .ce_tinline_courses .filters a {
    margin-bottom: 20px
  }
  .ce_tinline_courses .status-legend {
    margin-bottom: 2px
  }
  .ce_tinline_courses .status-legend ul li {
    float: none;
    margin-right: 0;
    margin-bottom: 8px;
    padding-right: 16px;
    padding-left: 0;
    text-align: right
  }
  .ce_tinline_courses .status-legend ul li:before {
    left: auto;
    right: 0
  }
  .ce_tinline_courses .course {
    margin-bottom: 9px;
    padding-bottom: 29px;
    border-top-width: 2px;
    border-bottom-width: 2px
  }
  .ce_tinline_courses .course .info-box-mobile {
    display: block
  }
  .ce_tinline_courses .course .info-box {
    display: none
  }
  .ce_tinline_courses .info-box-mobile > * {
    padding-left: 22px
  }
  .ce_tinline_courses .info-box-mobile .row {
    margin-bottom: 11px
  }
  .ce_tinline_courses .info-box-mobile .price {
    margin-bottom: 30px
  }
  .ce_tinline_courses .info-box-mobile .row:after {
    content: "";
    display: table;
    clear: both
  }
  .ce_tinline_courses .info-box-mobile .row > *:first-child {
    width: 65%;
    float: left
  }
  .ce_tinline_courses .info-box-mobile .row > *:last-child {
    width: 35%;
    float: left
  }
  .ce_tinline_courses .course .info-box-mobile > .title {
    margin-bottom: 15px;
    padding-left: 0
  }
  .ce_tinline_courses .course .title strong, .ce_tinline_courses .course .title span {
    padding-left: 22px
  }
  .ce_tinline_courses .course .title strong:before {
    left: 7px
  }
  .ce_tinline_courses .course .description {
    margin-bottom: 0;
    margin-left: 22px
  }
  .ce_tinline_courses .course .description p {
    margin-bottom: 10px
  }
  .ce_tinline_courses .course .link {
    margin-left: 22px
  }
  .ce_tinline_courses .individual-course {
    border-top-width: 2px;
    border-bottom-width: 2px
  }
  .ce_tinline_courses .individual-course p:last-child {
    text-align: center
  }
  #container {
    padding-bottom: 38px;
    padding-left: 10px;
    padding-right: 10px;
  }
  #left .inside {
    padding-right: 10px
  }
  #left .inside > * {
    margin-bottom: 30px
  }
  #left .mod_article {
    width: 206px;
    margin-right: auto;
    margin-bottom: 70px;
    margin-left: auto;
    padding-top: 40px
  }
  #footer {
    height: auto;
    position: static
  }
  #footer .top-box {
    height: auto;
    padding-top: 45px;
    padding-bottom: 45px;
    text-align: center
  }
  #footer .footer-menu {
    display: none
  }
  #footer .top-box .partners {
    float: none;
    justify-content: center;
    width: 100%;
    margin: 0
  }
  #footer .bottom-box {
    height: auto;
    padding-bottom: 14px;
    border-top-width: 2px
  }
  #footer .bottom-box .copyright {
    float: none;
    margin-right: 0
  }
  #footer .bottom-box .links {
    float: none
  }
  .page-layout-content #container {
    width: auto
  }
  body.page-layout-contact #header .mod_article {
    width: auto
  }
  body.page-layout-contact #header .intro__map {
    padding-right: 0;
    padding-left: 0
  }
  body.page-layout-contact #header .intro__map > * {
    height: 200px !important
  }
  body.page-layout-contact #header .intro__map:after {
    display: none
  }
  body.page-layout-contact #header .ce_text {
    margin-top: 20px
  }
  .contact-form .formbody {
    padding-top: 20px
  }
  body.page-layout-course #container {
    width: auto
  }
  body.page-layout-search #header:after {
    width: 100%
  }
  body.page-layout-search .mod_search .formbody {
    width: auto;
    margin-bottom: 30px
  }
  body.page-layout-search .mod_search > * {
    width: auto
  }
  body.page-layout-search .search-menu ul {
    height: auto
  }
  body.page-layout-search .search-menu ul li {
    height: auto;
    float: none;
    margin-right: 0;
    margin-bottom: 10px
  }
  body.page-layout-content .search-menu ul:after {
    display: none
  }
  body.page-layout-search .search-menu a, body.page-layout-search .search-menu span {
    border-bottom: 1px solid #e7e7e7
  }
  .article-home-news .mod_newslist .layout_latest {
    width: 33.333%
  }
  .mod_article.article-home-news .home-newslist {
    width: 100%;
    float: none;
    clear: both;
    margin-bottom: 40px
  }
  .mod_article.article-home-news .home-newslist .layout_latest.last {
    display: block
  }
  .article-home-news .mod_newslist {
    padding-right: 0;
    padding-left: 0
  }
  .article-home-news .home-newsticker {
    float: none;
    clear: both;
    background-image: url("../images/home-news-ticker-mobile.jpg");
    background-size: cover;
    min-height: 0;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px
  }
  .newslist-full .layout_latest {
    padding-right: 0;
    padding-left: 0
  }
  .news-navigation ul {
    flex-wrap: wrap;
    justify-content: space-around
  }
  .news-navigation ul li {
    margin-bottom: 20px;
    margin-left: 10px !important;
    margin-right: 10px !important
  }
  .support-box:after {
    content: "";
    display: table;
    clear: both
  }
  .support-box .inner {
    display: block
  }
  .support-box .inner > .phone, .support-box .inner > .email {
    width: 50%;
    float: left;
    margin-right: 0;
    margin-left: 0;
    padding-top: 28px;
    padding-left: 0;
    text-align: center;
    background-position: center top
  }
  .support-box .inner > .phone {
    background-image: url("../images/mobile/header-phone.png");
    background-size: 23px 23px
  }
  .support-box .inner > .email {
    background-image: url("../images/mobile/header-email.png");
    background-size: 23px 16px;
    background-position: center 5px
  }
  .article-team .ce_text {
    margin-bottom: 20px
  }
  .mod_tinline_course_reader .content-column {
    width: auto;
    float: none;
    margin-left: 305px
  }
}

@media (max-width: 767px) {
  /* mobile-small.css */
  .mobile-only {
    display: block
  }
  .article-home-news .mod_newslist .layout_latest {
    width: 100%
  }
  .article-home-news .layout_latest h2 {
    margin-bottom: 12px
  }
  .article-home-news .layout_latest .image_container {
    display: none
  }
  .article-home-featured .ce_image .image_container, .article-home-featured .ce_tinline_youtube .content, .article-home-featured .ce_tinline_youtube .content-inner {
    padding: 0;
    text-align: center
  }
  .page-layout-product #container {
    padding-left: 0
  }
  #left {
    width: 100%;
    right: 0
  }
  #left .inside {
    padding-top: 10px
  }
  #left .left-menu {
    display: none
  }
  .mod_tinline_course_reader .header {
    margin-bottom: 30px;
    padding-top: 51px
  }
  .mod_tinline_course_reader .header h1 {
    margin-bottom: 0;
    font-size: 30px;
    line-height: 40px
  }
  .mod_tinline_course_reader .header h1 + p {
    line-height: 22px
  }
  .mod_tinline_course_reader .details-column, .mod_tinline_course_reader .content-column {
    width: 100%;
    float: none;
    margin-right: 0
  }
  .mod_tinline_course_reader .content-column {
    margin-left: 0;
    padding-top: 8px
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .widget > label, .mod_tinline_course_reader .content-column .subscription-form .formbody .widget > fieldset > legend {
    width: auto
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .explanation {
    margin-left: 0
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .submit_container {
    text-align: center
  }
  .mod_tinline_course_reader .content-column .subscription-form .formbody .submit {
    margin-left: 0
  }
  .ce_cfg_tabs_wrapper_start .cfg_tabs_navigation ul li {
    margin-right: 0;
    margin-bottom: 9px
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a {
    position: relative;
    text-align: left;
    padding: 0 7px !important
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li .ce_cfg_tabs_tab_start a {
    border: none !important;
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a .name {
    padding: 10px 15px !important
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a.active .name {
    border-bottom: 2px solid #ebebeb
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a:after {
    width: 12px;
    height: 8px;
    right: 21px;
    top: 17px;
    position: absolute;
    background-image: url("../images/mobile/tabs-arrow-down.png");
    background-repeat: no-repeat;
    content: "";
    background-position: 90% center;
    background-size: 12px 8px
  }
  .cfg_tabs_wrapper.mobile .cfg_tabs_navigation ul li > a.active:after {
    background-image: url("../images/mobile/tabs-arrow-up.png")
  }
  .cfg_tabs_wrapper.mobile .ce_cfg_tabs_tab_start {
    padding-top: 36px;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 0px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-bottom: 2px solid #ebebeb;
    border-left: 1px solid #ebebeb
  }
  .cfg_tabs_wrapper.mobile .ce_cfg_tabs_tab_start .ce_text {
    text-align: left
  }
}
