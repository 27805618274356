.product-teaser {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media (max-width: 991px) {
        flex-direction: column;
        justify-content: normal;
    }

    &:nth-child(even) {
        .product-teaser__content {
            order: 2;
        }
    }

    &__headline {
        margin-bottom: 10px;
    }

    &__content {
        max-width: 450px;
        padding: 0 $gutter;

        @media (max-width: 991px) {
            padding: 0 10px;
            order: 1;
        }
    }

    &__main-image {
        margin-bottom: 0;
        max-width: 675px;
        padding: 0 $gutter;

        @media (max-width: 991px) {
            margin-bottom: 34px;
            padding: 0 10px;
        }
    }

    &__description {
        font-size: 14px;
        line-height: 24px;
    }

    &__images {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    &__image {
        margin: 0 0 20px 0;

        &-caption {
            margin-top: 5px;
            font-size: 13px;

            &-link {
                padding-left: 16px;
                background-position: left;
            }
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}
