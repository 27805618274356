.home-newslist-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $gutter;

    @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.home-newslist {
    display: flex;
    justify-content: space-between;
    max-width: 870px;
    padding: 15px;
    background: #fff;

    @media (max-width: 991px) {
        padding: 20px;
        flex-wrap: wrap;
        max-width: none;
    }

    &__article {
        display: flex;
        width: calc(50% - 30px);
        padding-right: 40px;

        @media (max-width: 991px) {
            width: 100%;
            padding-right: 0;

            &:not(:last-child) {
                position: relative;
                margin-bottom: 30px;
                padding-bottom: 30px;

                &::before {
                    content: '';
                    position: absolute;
                    left: calc(50% - 30px);
                    bottom: 0;
                    display: block;
                    height: 1px;
                    width: 60px;
                    background: #dcdedf;
                }
            }
        }

        &-image {
            min-width: 91px;
            margin: 0 20px 0 0;

            @media (max-width: 991px) {
                min-width: 82px;
            }
        }

        &-headline {
            margin-top: 0;
            margin-bottom: 3px;
            font-size: 18px;
            font-weight: 500;

            @media (max-width: 991px) {
                margin-bottom: 0;
                font-size: 15px;
            }

            a {
                color: #171715;
                text-decoration: none;
                transition: color .2s;

                &:hover {
                    color: #df000e;
                }
            }
        }

        &-description {
            line-height: 18px;
        }
    }
}

//.home-newslist-tino {
//    position: relative;
//    display: block;
//    width: 270px;
//    height: 81px;
//    padding: 20px 55px 20px 154px;
//    font-size: 18px;
//    font-weight: 500;
//    background-color: #fb0000;
//    color: #fff;
//    text-decoration: none;
//    transition: background-color .2s;
//
//    &:hover {
//        background: #171715;
//        color: #fff;
//    }
//
//    &::before {
//        content: '';
//        display: block;
//        width: 120px;
//        height: 120px;
//        position: absolute;
//        left: 11px;
//        bottom: 5px;
//        background: url('../../images/tino.png') no-repeat;
//    }
//}
